import Web3 from "web3"
import Notify from "bnc-notify"
import { toast } from "react-toastify"
const notify = Notify({
	dappId: process.env.REACT_APP_WALLET_API,
	networkId: 4,
})

const provider = window.ethereum
const web3 = new Web3(provider)

export async function sendTransaction(
	Receiverwalletaddress,
	SenderWalletAddress,
	Amount
) {
	return new Promise(async function(resolve, reject) {
		let data1 = []
		function emittxSent(data) {
			data1.push({
				txSent: data,
			})
		}

		function emittxConfirmed(data) {
			data1.push({
				txConfirmed: data,
			})
			resolve(data)
		}

		try {
			web3.eth
				.sendTransaction({
					from: SenderWalletAddress,
					to: Receiverwalletaddress,
					value: 1000000000000000000 * Amount,
				})
				.on("transactionHash", (hash) => {
					const { emitter } = notify.hash(hash)
					// use emitter to listen to transaction events
					emitter.on("txSent", (transaction) => {
						emittxSent(transaction)
					})
					emitter.on("txPool", console.log)
					emitter.on("txConfirmed", (transaction) => {
						emittxConfirmed(transaction)
					})
					emitter.on("txSpeedUp", console.log)
					emitter.on("txCancel", console.log)
					emitter.on("txFailed", console.log)
				})
		} catch (error) {
			console.log(error)
			toast(
				"The provided Wallet Address is Invalid, please cross check before trying again"
			)
		}
	})
}
