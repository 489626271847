import {combineReducers} from "redux";

import {loadingBarReducer} from "react-redux-loading";
import currentUser from './SetUser';
import resetPasswordId from './SetResetPasswordId'
import currentWalletInfo from './Wallets';

export default combineReducers({
    currentUser,
    resetPasswordId,
    currentWalletInfo,
    loadingBar:loadingBarReducer
})
