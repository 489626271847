import React, { Component } from "react"
import "./Widget.scss"

class WidgetTickerTape extends Component {
	constructor() {
		super()
	}

	componentDidMount() {
		if (document.getElementById("tickertape")) {
			const script = document.createElement("script")
			script.src =
				"https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js"
			script.async = true
			script.innerHTML = JSON.stringify({
				symbols: [
					{
						proName: "BITSTAMP:BTCUSD",
						title: "BTC/USD",
					},
					{
						proName: "BITSTAMP:ETHUSD",
						title: "ETH/USD",
					},
					{
						description: "XLM/USD",
						proName: "COINBASE:XLMUSD",
					},
					{
						description: "LTCUSD",
						proName: "COINBASE:LTCUSD",
					},
					{
						description: "BCHUSD",
						proName: "COINBASE:BCHUSD",
					},
					{
						description: "BSV/USD",
						proName: "BITFINEX:BSVUSD",
					},
				],
				colorTheme: "light",
				isTransparent: false,
				displayMode: "adaptive",
				locale: "in",
			})

			document.getElementById("tickertape").appendChild(script)
		}
	}

	render() {
		return <div id='tickertape'> </div>
	}
}

export default WidgetTickerTape
