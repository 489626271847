import React, { Component } from "react"
import { Button } from "antd"
import AppHeader from "../appHeader/AppHeader"

class WalletDetails extends Component {
	constructor(props) {
		super(props)

		this.buypage = (route) => {
			this.props.history.push({
				pathname: route,
			})
		}
	}

	render() {
		return (
			<React.Fragment>
				<header className='headerdiv'>
					<AppHeader />
				</header>

				<div className='walletDetails'>
					<div className='profilePageHeader'>Wallet</div>
					<div className='walletDetailsBody'>
						<div className='walletIconAndText'>
							<div className='walletCircle'>
								<div className='walletIcon'>
									<img
										style={{ height: 30, width: 30 }}
										alt=''
										src={require("../../images/ethereumicon.png")}
									></img>
								</div>
								<div>
									{this.props.location.coin.walletBalance} ETH
								</div>
								<div></div>
							</div>

							<div className='buyandsell'>
								<Button
									onClick={() => this.buypage("/exchangebuy")}
									className='coinButton buy'
									type='primary'
								>
									Buy
								</Button>
								<Button
									onClick={() =>
										this.buypage("/exchangesell")
									}
									className='coinButton sell'
									type='primary'
								>
									Sell
								</Button>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		)
	}
}

export default WalletDetails
