import React, { Component } from "react"
import { Link } from "react-router-dom"
import "../pages/Home.scss"

class NavBar extends Component {
	render() {
		return (
			<header
				id='header'
				className='u-header u-header--bg-transparent u-header--abs-top pt-3'
			>
				<div className='u-header__section'>
					<div id='logoAndNav' className='container'>
						<nav className='navbar navbar-expand u-header__navbar'>
							<a
								className='navbar-brand u-header__navbar-brand u-header__navbar-brand-center u-header__navbar-brand-text-white rounded-circle'
								href='#'
								aria-label='EinsteinExchange'
							>
								<Link
									style={{ color: "#ffffff", fontSize: 30 }}
									to='/'
								>
									{" "}
									<span style={{ color: "#ffc107" }}>
										{" "}
										Einstein
									</span>{" "}
									Exchange
								</Link>
							</a>

							<div className='ml-auto'>
								<a className='btn btn-sm btn-primary transition-3d-hover'>
									<Link
										style={{ color: "white" }}
										to='/signin'
									>
										{" "}
										Login
									</Link>
								</a>
							</div>
						</nav>
					</div>
				</div>
			</header>
		)
	}
}

export default NavBar
