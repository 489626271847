import React, { Component } from "react"
import { Link } from "react-router-dom"
import { Tabs } from "antd"
import { Table, Icon } from "antd"
import { transactionGet, GetTradeData } from "../../services/Api"
import { trackPromise } from "react-promise-tracker"
import { withRouter } from "react-router-dom"

class TransactionsTable extends Component {
	constructor(props) {
		super(props)
		this.state = {
			order: "",
			transactions: [],
		}

		this.ViewTransaction = () => {
			props.history.push("/viewtransactions")
		}
	}
	async componentDidMount() {
		trackPromise(
			this.getTransactionData().then((result) => {
				this.setState({
					transactions: result.data,
				})
			})
		)

		this.getTradeData()
	}

	async getTransactionData() {
		const Transactions = await transactionGet()
		return Transactions
	}

	async getTradeData() {
		const TradeData = await GetTradeData()

		console.log(TradeData.data)
	}

	render() {
		const total = this.state.transactions.length
		const limit = 5
		const columns = [
			{
				title: "Date",
				dataIndex: "createdAt",
				key: "createdAt",
				width: 80,
			},
			{
				title: "Coin",
				dataIndex: "asset",
				key: "asset",
				width: 80,
				render: (text, record) => (
					<Link to={"/viewtransactions/"}>
						<a style={{ color: "green" }}>{text} </a>
					</Link>
				),
			},
			{
				title: "Coin Amount",
				dataIndex: "value",
				key: "value",
				width: 80,
				render: (text, record) => (
					<a style={{ color: "green" }}>
						{text / 1000000000000000000}{" "}
					</a>
				),
			},
			{ title: "Receiver ", dataIndex: "to", key: "to", width: 110 },
			{
				title: "Transaction Hash ",
				dataIndex: "hash",
				key: "hash",
				width: 110,
			},
		]
		return (
			<div className='table-responsive tableBg'>
				<Table
					bordered
					columns={columns}
					dataSource={this.state.transactions}
					className='cardtable'
					onChange={this.paginationFun}
					scroll={{ x: 1200 }}
					rowKey='id'
					pagination={{
						size: "small",
						total: total,
						pageSize: limit,
						showTotal: (total) => `Total ${total} Transactions`,
					}}
				/>
			</div>
		)
	}
}
export default withRouter(TransactionsTable)
