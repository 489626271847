import { createStore } from "redux"
import ReactDOM from "react-dom"
import Loader from "../src/components/loader/Loader"
import middleware from "./redux/middleware"
import { Provider } from "react-redux"
import "antd/dist/antd.less"
import "antd/dist/antd.css"
import "./style.scss"
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/css/bootstrap.css"
import "animate.css/animate.min.css"
import "react-toastify/dist/ReactToastify.css"
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import App from "./components/App"
import combineReducers from "./redux/reducers/index"
import React from "react"
window.onload = () => {
	"use strict"
	if ("serviceWorker" in navigator) {
		window.addEventListener("load", function() {
			navigator.serviceWorker.register("/ServiceWorker.js").then(
				function(registration) {
					// Registration successful
					console.log(
						"ServiceWorker registration was successful with scope: ",
						registration.scope
					)
				},
				function(err) {
					// Registration failed
					console.log(
						"ServiceWorker registration failed with error: ",
						err
					)
				}
			)
		})
	}
}

export const store = createStore(combineReducers, middleware)

ReactDOM.render(
	<Provider store={store}>
		<App />
		<Loader />
	</Provider>,

	document.getElementById("root")
)
