export const TimeStampToDate = (data) => {
	var a = new Date(data * 1000)
	var months = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec",
	]
	var year = a.getFullYear()
	var month = months[a.getMonth()]
	var date = a.getDate()
	var time = date + " " + month + " " + year
	return time
}

export const TimeStampToTime = (data) => {
	const time_to_show = data
	const t = new Date(time_to_show * 1000)
	const formatted =
		("0" + t.getHours()).slice(-2) + ":" + ("0" + t.getMinutes()).slice(-2)
	return formatted
}

export const MongoTimeConvert = (time) => {
	const localDate = new Date(time)
	const str = localDate.toString()
	const res = str.substring(4, 21)
	return res
}
