import React from "react";
import { Route, Redirect } from "react-router-dom";
import auth from "./Auth";
const SET_TOKEN='SET_TOKEN'
export const ProtectedRoute = ({
  component: Component,
  ...rest
}) => {
  return (
    <Route
      { ...rest}
      render={props => {
       
        if (auth.isAuthenticated()) {
          return (<Component {...props} />);
        } else {
          return (
           (<Redirect
              to={{
                pathname: "/home",
                state: {
                  from: props.location
                }
              }}
            />)
          );
        }
      }}
    ></Route>
  );
};
