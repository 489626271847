import request from "../utils/Request"
import {
	ExternalRequests,
	resetPassword,
	searchSellers,
} from "../utils/Request"

//User account
export function login(params) {
	console.log("hawa hapa", params)
	return request("users/login", { method: "POST", body: params })
}

export function register(params) {
	return request("users/signUp", { method: "POST", body: params })
}
export function forgot(params) {
	return request("users/forgotPasswordOtp", { method: "POST", body: params })
}
export function resetpass(params) {
	return resetPassword("users/resetPassword", { method: "PUT", body: params })
}
export function changePassword(params) {
	return request("users/resetPassword", { method: "PUT", body: params })
}
export function profileGet() {
	return request("users/getProfile", { method: "GET" })
}

export function transactionGet() {
	return request("hooks/transactions", { method: "GET" })
}

export function addToWatchList(params) {
	return request("users/watchList", { method: "POST", body: params })
}

export function removeFromWatchList(params) {
	return request("users/watchList", { method: "DELETE", body: params })
}

export function getWatchList() {
	return request("users/watchList", { method: "GET" })
}

export function profilePut(params) {
	return request("users/updateProfile", { method: "PUT", body: params })
}

//wallets
export function walletLink(params) {
	return request("users/wallets", { method: "POST", body: params })
}
export function walletUpdate(params) {
	return request("users/wallets", { method: "PUT", body: params })
}
export function AddWalletToWatch(params) {
	return ExternalRequests(process.env.REACT_APP_ADDTRANSACTIONTOWATCH, {
		method: "POST",
		body: params,
	})
}

//changelly
export function changellyGetCurrencies() {
	return request("changelly/getCurrencies", { method: "GET" })
}
export function changellyGetMinimumAmount(params) {
	return request("changelly/getMinAmount", { method: "POST", body: params })
}

export function changellyGetExchangeAmount(params) {
	return request("changelly/getExchangeAmount", {
		method: "POST",
		body: params,
	})
}

export function changellycreateTransaction(params) {
	return request("changelly/createTransaction", {
		method: "POST",
		body: params,
	})
}
export function GetTradeData() {
	return request("changelly/getTrade", { method: "GET" })
}

export function changellyGetStatus(params) {
	return request("changelly/getStatus", { method: "POST", body: params })
}

export function getNotification() {
	return request("users/getNotification", { method: "GET" })
}

export function UpdateNotificationStatus(params) {
	return request("users/isReadNotification", { method: "PUT", body: params })
}

export function showInterest(params) {
	return request("buyers/showInterest", { method: "POST", body: params })
}

export function UpdateSellingList(params) {
	return request("buyers/updateSellerList", { method: "PUT", body: params })
}

//tradeApis
export function searchSeller(params) {
	return searchSellers("sellers/searchseller", {
		method: "GET",
		body: params,
	})
}

export function addSeller(params) {
	return request("sellers/addSeller", { method: "POST", body: params })
}

export function getUsersListedCoins() {
	return request("sellers/mySellingCoin", { method: "GET" })
}
