import React, { Component } from "react"
import { Link } from "react-router-dom"
import "./AppSidebar.scss"
import { Menu, Icon, Layout } from "antd"
const { SubMenu } = Menu

const { Sider } = Layout

const menu = [
	{
		path: `/`,
		name: "Dashboard",
		image: require("../../icons/dashboard.svg"),
		key: 1,
	},
	{
		path: `/coins`,
		name: "Coins",
		image: require("../../icons/coin.svg"),
		key: 2,
	},
	{
		path: `/exchangebuy`,
		name: "Buy-Sell",
		image: require("../../icons/exchange-arrows.svg"),
		key: 3,
	},
	{
		path: `/portfolio`,
		name: "Portfolio",
		image: require("../../icons/bag.svg"),
		key: 4,
	},
	
]

class AppSidebar extends Component {
	render() {
		const { location } = this.props
		const pathSnippets = location.pathname.split("/").filter((i) => i)
		const pathval = pathSnippets[pathSnippets.length - 1] || "/"

		return (
			<Sider>
				<Menu mode='inline' defaultOpenKeys={[""]} className='sidemenu'>
					{menu.map((item) => {
						return (
							<Menu.Item
								defaultSelectedKeys={item.path}
								key={item.path}
							>
								<Link to={item.path}>
									<div className='coinTitle active'>
										<img src={item.image}></img>

										<div className='coinName'>
											{item.name}
										</div>
									</div>
								</Link>
							</Menu.Item>
						)
					})}
				</Menu>
			</Sider>
		)
	}
}
export default AppSidebar
