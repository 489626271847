import React, { Component } from "react"
import { Card, Row, Col, Icon } from "antd"
import "./Style.scss"
import { NewsApi } from "../../services/Cryptoapis"
import NewsModal from "../Modals/NewsModal"
import { TimeStampToDate } from "../../converters/timeConverters"
import { withRouter } from "react-router-dom"
import { trackPromise } from "react-promise-tracker"

const { Meta } = Card

class News extends Component {
	constructor(props) {
		super(props)
		this.state = {
			short: "",
			mounted: false,
			visible: false,
			image: "",
			title: "",
			description: "",
			time: "",
			link: "",
			news: [],
		}

		this.showModal = (image, title, description, time, url) => {
			this.setState({
				visible: true,
				image: image,
				title: title,
				description: description,
				time: TimeStampToDate(time),
				link: url,
			})
		}

		this.saveFormRef = (formRef) => (this.formRef = formRef)
		this.handleCancel = (e) =>
			this.setState({ visible: false, error: false })

		this.goBack = () => {
			this.props.history.push({
				pathname: "/",
			})
		}
	}

	async componentDidMount() {
		trackPromise(
			this.getNews().then((result) => {
				console.log("Result", result)
				this.setState({
					news: result,
				})
			})
		)
	}

	async getNews() {
		const News = await NewsApi()
		return News
	}

	componentWillUnmount() {
		clearInterval(this.intervalID)
	}

	render() {
		return (
			<div>
				<div className='coinHeader'>
					<div className='coinTitle'>
						<Icon
							onClick={() => this.props.history.goBack()}
							type='arrow-left'
							style={{ fontSize: 20 }}
						></Icon>
					</div>
				</div>
				<Row gutter={15} justify='space-between' align='middle'>
					{this.state.news.map((item, key) => (
						<Col
							span={12}
							md={{ span: 12 }}
							lg={{ span: 8 }}
							style={{ paddingTop: 15, marginTop: 10 }}
						>
							<Card
								className='dashCard'
								cover={
									<img alt='example' src={item.imageurl} />
								}
								actions={[
									<a
										onClick={() =>
											this.showModal(
												item.imageurl,
												item.title,
												item.body,
												item.published_on,
												item.url
											)
										}
									>
										{" "}
										Quick View{" "}
									</a>,
									<a href={item.url} target='_blank'>
										{" "}
										Read{" "}
									</a>,
								]}
							>
								<div style={{ fontSize: 10, paddingRight: 5 }}>
									<Meta
										title={item.title}
										description={item.categories}
									/>
								</div>
							</Card>
						</Col>
					))}
				</Row>
				<NewsModal
					wrappedComponentRef={this.saveFormRef}
					visible={this.state.visible}
					image={this.state.image}
					topic={this.state.title}
					description={this.state.description}
					link={this.state.link}
					onCancel={this.handleCancel}
					time={this.state.time}
					onCreate={this.handleCreate}
				/>
			</div>
		)
	}
}
export default withRouter(News)
