import React, { Component } from "react"
import { Card, Checkbox, Button, Icon, Form, Select } from "antd"
import { sendTransaction } from "../../services/Ethtransactions"
import { profileGet, UpdateSellingList } from "../../services/Api"
import { Container } from "react-bootstrap"

import { toast } from "react-toastify"
const { Option } = Select
class SellCoin extends Component {
	constructor(props) {
		super(props)
		this.state = {
			wallets: [],
			selectedWallet: "",
		}

		this.sellCoin = async () => {
			let ReceiverWalletaddress = props.location.coin.walletAdress
			let SenderWalletAddress = this.state.selectedWallet
			console.log("Address", SenderWalletAddress)
			let Amount = props.location.coin.coinQty
			sendTransaction(ReceiverWalletaddress, SenderWalletAddress, Amount)

			const update = {
				sellerListId: this.props.location.coin.sellerListId,
				status: "sold",
			}
			const updateSellingList = await UpdateSellingList(update)

			toast(updateSellingList.message)
		}

		this.handleWalletChange = (value) => {
			console.log(value)
			this.setState({
				selectedWallet: value,
			})
		}
	}

	async componentDidMount() {
		const Profile = await profileGet()
		this.setState({
			wallets: Profile.data.wallets,
		})
	}

	render() {
		const props = this.props

		const { getFieldDecorator } = props.form

		return (
			<React.Fragment>
				<Container>
					<div className='coinHeader'>
						<div className='coinTitle'>
							<Icon
								onClick={() => this.props.history.goBack()}
								type='arrow-left'
								style={{ fontSize: 20 }}
							></Icon>
							<div className='coinName'>Sell Coin</div>
						</div>
					</div>
					<div className='buyCoin'>
						<div className='buyCoinBody'>
							<div className='buyCoinContent'>
								<div className='buyContentText'>
									{" "}
									{this.props.location.coin.buyerName} wants
									to buy from You
								</div>

								<div className='buyCoinsCards'>
									<div className='buyCoinsCards1'>
										<div className='buyCoinImageHeaders'>
											{" "}
											YOU SEND
										</div>
										<Card style={{ borderRadius: 10 }}>
											<div className='buyCoinImage'>
												<img
													style={{
														height: 50,
														width: 50,
													}}
													alt=''
													src={require("../../images/ethereumicon.png")}
												></img>
											</div>
											<div class='buyCoinsValue'>
												{
													this.props.location.coin
														.coinQty
												}{" "}
												{
													this.props.location.coin
														.coinName
												}
											</div>
										</Card>
									</div>
									<div className='buyCoinsCards1'>
										<div className='buyCoinImageHeaders'>
											{" "}
											YOU GET
										</div>
										<Card style={{ borderRadius: 10 }}>
											<div className='buyCoinImage'>
												<img
													style={{
														height: 50,
														width: 50,
													}}
													alt=''
													src={require("../../images/dollaricon.png")}
												></img>
											</div>
											<div class='buyCoinsValue'>
												$
												{this.props.location.coin.amount.toLocaleString(
													navigator.language,
													{ minimumFractionDigits: 2 }
												)}
											</div>
										</Card>
									</div>
								</div>
								<div className='sellCoinsWalletAddress'>
									{" "}
									{this.props.location.coin.buyerName} Wallet
									Address
								</div>
								<div className='buyContentText'>
									<input
										className='inPutBoxDisabled'
										type='text'
										value={
											this.props.location.coin
												.walletAdress
										}
										placeholder={
											this.props.location.coin
												.walletAdress
										}
										disabled
									></input>
								</div>

								<div className='buyContentText'>
									<div className='buySectionPaymentSection'>
										<div className='selectCoin'>
											<div className='buySectionPaymentSection'>
												<div className='buyContentText'>
													<div className='paymentHeader'>
														Payment Method
													</div>
												</div>
												<Form
													labelCol={{ span: 5 }}
													wrapperCol={{ span: 12 }}
													onSubmit={this.handleSubmit}
												>
													<div className='walletList'>
														<Form.Item>
															{getFieldDecorator(
																"wallet",
																{
																	rules: [
																		{
																			required: true,
																			message:
																				"Please select  coins!",
																		},
																	],
																}
															)(
																<Select
																	onChange={
																		this
																			.handleWalletChange
																	}
																>
																	{this.state.wallets.map(
																		(
																			item,
																			key
																		) => (
																			<Option
																				value={
																					item.walletAdress
																				}
																			>
																				{
																					item.walletName
																				}
																			</Option>
																		)
																	)}
																</Select>
															)}
														</Form.Item>
													</div>
													<div className='termsAndConditions'>
														<Checkbox>
															I agree to{" "}
															<span
																style={{
																	color:
																		"#e4b28d",
																}}
															>
																Terms and
																conditions
															</span>
														</Checkbox>{" "}
													</div>
												</Form>

												<div className='buyContentText'>
													<div className='buttonSection'>
														<Button
															onClick={
																this.sellCoin
															}
															className='buyCoinSellButton'
														>
															Sell{" "}
														</Button>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Container>
			</React.Fragment>
		)
	}
}

export default Form.create()(SellCoin)
