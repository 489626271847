import React, { Component } from "react"
import { Tabs } from "antd"
import {
	Table,
	ToggleButtonGroup,
	ButtonToolbar,
	ToggleButton,
	InputGroup,
	FormControl,
} from "react-bootstrap"
import { Icon } from "antd"
import { withRouter } from "react-router-dom"
import TradeData from "../tables/TradeData"
import TransactionsTable from "../tables/TransactionsTable"

const { TabPane } = Tabs

function callback(key) {
	console.log(key)
}

class Transactions extends Component {
	constructor(props) {
		super(props)
		this.state = {
			order: "",
			transactions: [],
		}
	}

	render() {
		return (
			<React.Fragment>
				<div className='exchangeHeader'>
					<div className='PorfolioHeader'> My Transactions</div>

					<div className='totalHoldingsTitle'>
						<div className='SearchBar'>
							<div className='SearchBarContents'>
								<InputGroup className='mb-3'>
									<FormControl
										placeholder='Search'
										aria-label='Amount (to the nearest dollar)'
									/>
									<InputGroup.Append>
										<InputGroup.Text>
											<Icon
												style={{ fontSize: 20 }}
												type='search'
											></Icon>
										</InputGroup.Text>
									</InputGroup.Append>
								</InputGroup>
							</div>
							<div>
								<ButtonToolbar className='toggleBtnGroup'>
									<ToggleButtonGroup
										type='radio'
										name='options'
										defaultValue={1}
									>
										<ToggleButton
											onClick={this.handleClick}
											value={1}
										>
											All
										</ToggleButton>
										<ToggleButton
											onClick={this.handleClick}
											value={3}
										>
											Pending{" "}
										</ToggleButton>
									</ToggleButtonGroup>
								</ButtonToolbar>
							</div>
						</div>
					</div>
				</div>
				<TransactionsTable />
			</React.Fragment>
		)
	}
}
export default withRouter(Transactions)
