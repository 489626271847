import React, { Component } from "react"
import {
	Card,
	Button,
	Input,
	Row,
	Col,
	Icon,
	Form,
	Checkbox,
	message,
} from "antd"
import { Link } from "react-router-dom"
import auth from "../components/protectedRoute/Auth"
import ForgotForm from "../components/Modals/Forgot"
import { login, forgot } from "../services/Api"
import { toast } from "react-toastify"
const SET_TOKEN = "SET_TOKEN"
toast.configure()
class Login extends Component {
	constructor() {
		super()
		this.state = {
			showpassword: false,
			username: "",
			password: "",
			visible: false,
			numberChar: false,
			capitalChar: false,
			smallChar: false,
			confirmDirty: false,
			specialChar: "",
			charlength: false,
		}
		this.showModal = () => {
			this.setState({ visible: true })
		}
		this.saveFormRef = (formRef) => (this.formRef = formRef)
		this.handleCancel = (e) =>
			this.setState({ visible: false, recovermail: "", error: false })
		this.handleCreate = () => {
			const form = this.formRef.props.form
			form.validateFields(async (err, values) => {
				const resetPasswordValue = {
					...values,
				}
				console.log(values)
				const response = await forgot(resetPasswordValue)
				if (
					response.message ===
					"OTP has sent successfully to registered email id!"
				) {
					toast(response.message, { type: "success" })
					this.props.history.push({
						pathname: "/resetPassword",
						id: response.data._id,
					})
				}
			})
		}

		this.handleSubmit = (e) => {
			e.preventDefault()
			this.props.form.validateFields(async (err, values) => {
				if (!err) {
					const userValues = {
						...values,
					}

					const LoginValue = await login(userValues)
					if (
						LoginValue.message === "Successfully authenticated!" &&
						LoginValue.data.isVerified
					) {
						localStorage.setItem(SET_TOKEN, LoginValue.data.token)
						auth.login(() => {
							this.props.history.push("/")
						})
					} else {
						toast(LoginValue.message, { type: "error" })
						this.props.history.push("/signin")
					}
				}
			})
		}
	}

	showPass() {
		this.setState({ showpassword: !this.state.showpassword })
	}

	render() {
		const {
			visible,
			onCancel,
			onCreate,
			form,
			receiversWallet,
		} = this.props
		const { getFieldDecorator } = form

		const {
			showpassword,
			numberChar,
			capitalChar,
			smallChar,
			specialChar,
			TooltipShow,
			charlength,
		} = this.state
		const PassPattern = (
			<ul className='PassPattern'>
				<li className={capitalChar ? "active" : ""}>
					At least one capital char
				</li>
				<li className={smallChar ? "active" : ""}>
					At least one small char
				</li>
				<li className={numberChar ? "active" : ""}>
					At least one numeric
				</li>
				<li className={specialChar ? "active" : ""}>
					At least one special char
				</li>
				<li className={charlength ? "active" : ""}>
					Minimum 6 char required
				</li>
			</ul>
		)
		return (
			<React.Fragment>
				<div className='loginImage'>
					<div className='loginCardLayout'>
						<Card
							className='loginCard'
							style={{ borderRadius: 24 }}
						>
							<div className='modalPayment'>
								<div className='LoginHeader'>
									<div className='header'>
										<img
											src={require("../images/group-649.svg")}
										></img>
										{/* Einstein Exchange */}
									</div>
									<div className='loginText'>Login</div>
								</div>
								<Form
									labelCol={{ span: 5 }}
									wrapperCol={{ span: 12 }}
									onSubmit={this.handleSubmit}
								>
									<Form.Item>
										{getFieldDecorator("email", {
											initialValue: receiversWallet,
											rules: [
												{
													required: true,
													message:
														"Please input an email",
												},
											],
										})(
											<Input placeholder='Email Address' />
										)}
									</Form.Item>

									<Form.Item>
										{getFieldDecorator("password", {
											rules: [
												{
													required: true,
													message:
														"Please input your password",
												},
											],
										})(
											<Input
												type={
													showpassword
														? "text"
														: "password"
												}
												placeholder='New Password'
												className='eyeicon'
												minLength={6}
												maxLength={20}
												suffix={
													<img
														src={
															this.state
																.showpassword
																? require("../images/eye-off.svg")
																: require("../images/eye.svg")
														}
														alt='eye'
														onClick={() =>
															this.showPass()
														}
													/>
												}
											/>
										)}
									</Form.Item>
									<div className='forgotPasswordSection'>
										<div className='checkBox'>
											<span>
												<Checkbox />
											</span>{" "}
											Remember Me
										</div>
										<div
											onClick={() => this.showModal()}
											className='forgot'
										>
											{" "}
											Forgot Password
										</div>
									</div>

									<Button
										className='pricingButtonStyle pricingButton'
										type='primary'
										htmlType='submit'
									>
										Login
									</Button>
								</Form>

								<div className='registerSection'>
									<div className='question'>
										{" "}
										Don't have an account?
									</div>
									<div className='register'>
										{" "}
										<Link to='/signin'> Register here</Link>
									</div>
								</div>
							</div>
						</Card>
					</div>
				</div>
				<ForgotForm
					wrappedComponentRef={this.saveFormRef}
					visible={this.state.visible}
					onCancel={this.handleCancel}
					onCreate={this.handleCreate}
				/>
			</React.Fragment>
		)
	}
}

export default Form.create()(Login)
