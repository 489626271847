import React, { Component } from "react"
import { Card, Checkbox, Button, Icon, Form, Select } from "antd"
import { profileGet, showInterest } from "../../services/Api"
import { toast } from "react-toastify"
import { Container } from "react-bootstrap"
const { Option } = Select
class BuyCoin extends Component {
	constructor(props) {
		super(props)
		this.state = {
			wallets: [],
			walletName: "",
			walletBalance: "",
			walletAddress: "",
			buyerName: "",
		}

		this.handleWalletChange = (value) => {
			const walletInfo = JSON.parse(value)
			console.log(walletInfo)

			this.setState({
				walletName: walletInfo.name,
				walletBalance: walletInfo.balance,
				walletAddress: walletInfo.walletAddress,
			})
		}

		this.handleSubmitBuyRequest = async () => {
			const SubmitBuyRequest = {
				sellerListId: this.props.location.coin.sellerid,
				sellerId: this.props.location.coin.sellerUserid,
				buyerName: this.state.buyerName,
				coinName: this.props.location.coin.name,
				coinQty: this.props.location.coin.quantity,
				amount: this.props.location.coin.amount,
				walletName: this.state.walletName,
				walletAdress: this.state.walletAddress,
			}

			console.log(SubmitBuyRequest)
			const showInterestInSeller = await showInterest(SubmitBuyRequest)
			console.log(showInterestInSeller)
			toast(showInterestInSeller.data)
		}
	}

	async componentDidMount() {
		const Profile = await profileGet()
		this.setState({
			wallets: Profile.data.wallets,
			buyerName: Profile.data.fullName,
		})
		console.log(this.props.location.coin)
	}
	render() {
		const props = this.props

		const { getFieldDecorator } = props.form
		return (
			<React.Fragment>
				<Container>
					<div className='coinHeader'>
						<div className='coinTitle'>
							<Icon
								onClick={() => this.props.history.goBack()}
								type='arrow-left'
								style={{ fontSize: 20 }}
							></Icon>
							<div className='coinName'>Buy Coin</div>
						</div>
					</div>
					<div className='buyCoin'>
						<div className='buyCoinBody'>
							<div className='buyCoinContent'>
								<div className='buyContentText'>
									{" "}
									You are buying from{" "}
									{this.props.location.coin.seller}
								</div>

								<div className='buyCoinsCards'>
									<div className='buyCoinsCards1'>
										<div className='buyCoinImageHeaders'>
											{" "}
											YOU GET
										</div>
										<Card style={{ borderRadius: 10 }}>
											<div className='buyCoinImage'>
												<img
													style={{
														height: 50,
														width: 50,
													}}
													alt=''
													src={require("../../images/ethereumicon.png")}
												></img>
											</div>
											<div class='buyCoinsValue'>
												{
													this.props.location.coin
														.quantity
												}
												{this.props.location.coin.name}
											</div>
										</Card>
									</div>
									<div className='buyCoinsCards1'>
										<div className='buyCoinImageHeaders'>
											{" "}
											YOU PAY
										</div>
										<Card style={{ borderRadius: 10 }}>
											<div className='buyCoinImage'>
												<img
													style={{
														height: 50,
														width: 50,
													}}
													alt=''
													src={require("../../images/dollaricon.png")}
												></img>
											</div>
											<div class='buyCoinsValue'>
												${" "}
												{this.props.location.coin.amount.toLocaleString(
													navigator.language,
													{ minimumFractionDigits: 2 }
												)}
											</div>
										</Card>
									</div>
								</div>

								<div className='buyContentText'>
									<div className='buySectionPaymentSection'>
										<div className='selectCoin'>
											<div className='buySectionPaymentSection'>
												<div className='buyContentText'>
													<div className='paymentHeader'>
														Payment Method
													</div>
												</div>
												<Form
													labelCol={{ span: 5 }}
													wrapperCol={{ span: 12 }}
													onSubmit={this.handleSubmit}
												>
													<div className='walletList'>
														<Form.Item>
															{getFieldDecorator(
																"wallet",
																{
																	rules: [
																		{
																			required: true,
																			message:
																				"Please select  coins!",
																		},
																	],
																}
															)(
																<Select
																	onChange={
																		this
																			.handleWalletChange
																	}
																>
																	{this.state.wallets.map(
																		(
																			item,
																			key
																		) => (
																			<Option
																				value={JSON.stringify(
																					{
																						name:
																							item.walletName,
																						walletAddress:
																							item.walletAdress,
																						balance:
																							item.balance,
																					}
																				)}
																			>
																				{
																					item.walletName
																				}
																			</Option>
																		)
																	)}
																</Select>
															)}
														</Form.Item>
													</div>
													<div className='termsAndConditions'>
														<Checkbox>
															I agree to{" "}
															<span
																style={{
																	color:
																		"#e4b28d",
																}}
															>
																Terms and
																conditions
															</span>
														</Checkbox>{" "}
													</div>
												</Form>

												<div className='buyContentText'>
													<div className='buttonSection'>
														<Button
															onClick={
																this
																	.handleSubmitBuyRequest
															}
															className='buyCoinSellButton'
														>
															Buy{" "}
														</Button>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Container>
			</React.Fragment>
		)
	}
}

export default Form.create()(BuyCoin)
