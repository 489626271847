import React, { Component } from "react"
import { toast } from "react-toastify"
import "../../layout/MainLayout.scss"
import "../../style.scss"
import {
	Card,
	Form,
	Input,
	Button,
	message,
	Tooltip,
	Row,
	Col,
	Icon,
	Switch,
} from "antd"
import { profileGet, changePassword } from "../../services/Api"
import AppHeader from "../appHeader/AppHeader"
import WalletsAdd from "../../payments/WalletsAdd"
import CardModal from "../Modals/CardModal"
const FormItem = Form.Item
class NewProfile extends Component {
	constructor(props) {
		super(props)
		this.state = {
			username: "",
			password: "",
			visible: false,
			cardvisible: false,
			email: "",
			wallets: [],
			recovermail: "",
			error: false,
			showpassword: false,
			token: this.props.match.params.token,
			loading: false,
			confirmDirty: false,
			numberChar: false,
			capitalChar: false,
			smallChar: false,
			specialChar: "",
			charlength: false,
			TooltipShow: false,
			disabled: true,
			display: "none",
		}

		this.showModal = () => {
			this.setState({ cardvisible: true })
		}
		this.showPass = () => {
			this.setState({ showpassword: !this.state.showpassword })
		}
		this.saveFormRef = (formRef) => (this.formRef = formRef)
		this.handleCancel = (e) =>
			this.setState({ cardvisible: false, error: false })
		this.handleSubmit = (e) => {
			e.preventDefault()
			this.props.form.validateFields(async (err, values) => {
				if (!err) {
					if (values.password === values.confirm) {
						const passwordInfo = {
							password: values.password,
							oldPassword: values.oldPassword,
						}
						var ChangedPassword = await changePassword(passwordInfo)
						toast(ChangedPassword.message)
						if (
							ChangedPassword.message ===
							"Password updated successfully"
						) {
							this.props.history.push("/signin")
						}
					} else
						message.error(
							"Password does not match the confirm password."
						)
				}
			})
		}

		this.compareToFirstPassword = (rule, value, callback) => {
			const form = this.props.form
			if (value && value !== form.getFieldValue("password")) {
				callback("Please enter same password")
			} else {
				callback()
			}
		}
		this.enableText = () => {
			this.setState({
				disabled: false,
				display: "block",
				visible: true,
			})
		}
		this.disabledButton = () => {
			this.setState({
				disabled: true,
				display: "none",
				visible: false,
			})
		}
	}

	async componentDidMount() {
		const Profile = await profileGet()
		this.setState({
			username: Profile.data.fullName,
			email: Profile.data.email,
			wallets: Profile.data.wallets,
		})
	}
	render() {
		const { getFieldDecorator } = this.props.form
		const {
			showpassword,
			numberChar,
			capitalChar,
			smallChar,
			specialChar,
			TooltipShow,
			charlength,
		} = this.state
		const PassPattern = (
			<ul className='PassPattern'>
				<li className={capitalChar ? "active" : ""}>
					At least one capital char
				</li>
				<li className={smallChar ? "active" : ""}>
					At least one small char
				</li>
				<li className={numberChar ? "active" : ""}>
					At least one numeric
				</li>
				<li className={specialChar ? "active" : ""}>
					At least one special char
				</li>
				<li className={charlength ? "active" : ""}>
					Minimum 6 char required
				</li>
			</ul>
		)

		return (
			<React.Fragment>
				<header className='headerdiv'>
					<AppHeader />
				</header>
				<div className='profile'>
					<div className='profilePageHeader'>My Profile</div>

					<div className='profileBody'>
						<Row>
							<Col md={8}>
								<div className='profileImage'>
									<img
										style={{
											borderRadius: 14,
											height: 150,
											width: 200,
										}}
										alt='example'
										src='https://images.unsplash.com/photo-1559526324-593bc073d938?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1080&fit=max&ixid=eyJhcHBfaWQiOjF9'
									/>
									<div className='profileName'>
										{this.state.username}
									</div>
									<div className='changePassword'>
										Change Password{" "}
									</div>
								</div>
							</Col>
							<Col md={8}>
								<div className='basicInfo'>
									<div className='infoHeading'>
										<div className='infoButton'>
											{" "}
											Basic Info
										</div>
										<div className='editIcon'>
											<Icon
												onClick={this.enableText}
												type='form'
											></Icon>
										</div>
										<div
											onClick={this.enableText}
											className='editIconText'
										>
											Edit
										</div>
									</div>

									<Form onSubmit={this.handleSubmit}>
										<label>Full Name</label>
										<FormItem>
											{getFieldDecorator("Fullname", {
												initialValue: this.state
													.username,
												rules: [
													{
														required: true,
														message:
															"Please enter your Fullname!",
													},
												],
											})(
												<Input
													placeholder='Username'
													maxLength={20}
													className='inputBox'
													disabled={
														this.state.disabled
															? "disabled"
															: ""
													}
												/>
											)}
										</FormItem>
										<label>Email</label>
										<FormItem>
											{getFieldDecorator("email", {
												initialValue: this.state.email,
												rules: [
													{
														required: true,
														message:
															"Please enter your email!",
													},
												],
											})(
												<Input
													type={"email"}
													placeholder='email'
													maxLength={20}
													className='inputBox'
													disabled={
														this.state.disabled
															? "disabled"
															: ""
													}
												/>
											)}
										</FormItem>
										<FormItem>
											{getFieldDecorator("oldPassword", {
												rules: [
													{
														required: true,
														message:
															"Please enter your old password!",
													},
												],
											})(
												<Input
													type={"password"}
													placeholder='Old Password'
													className='inputBox'
													maxLength={20}
													style={{
														display: this.state
															.display,
													}}
												/>
											)}
										</FormItem>
										<FormItem>
											<Tooltip
												placement='bottomLeft'
												title={PassPattern}
												trigger={"focus"}
												className='intooltip'
												visible={TooltipShow}
											>
												{getFieldDecorator("password", {
													rules: [
														{
															required: true,
															message:
																"Please input your password!",
														},
													],
												})(
													<Input
														type={
															showpassword
																? "text"
																: "password"
														}
														placeholder='New Password'
														className='eyeicon inputBox'
														minLength={6}
														maxLength={20}
														suffix={
															<img
																src={
																	this.state
																		.showpassword
																		? require("../../images/eye-off.svg")
																		: require("../../images/eye.svg")
																}
																alt='eye'
																onClick={() =>
																	this.showPass()
																}
															/>
														}
														onBlur={() =>
															this.setState({
																TooltipShow: false,
															})
														}
														style={{
															display: this.state
																.display,
														}}
													/>
												)}
											</Tooltip>
										</FormItem>
										<FormItem>
											{getFieldDecorator("confirm", {
												rules: [
													{
														required: true,
														message:
															"Please confirm your password!",
													},
													{
														validator: this
															.compareToFirstPassword,
													},
												],
											})(
												<Input
													alt=''
													type={
														showpassword
															? "text"
															: "password"
													}
													placeholder='Confirm Password'
													onBlur={
														this.handleConfirmBlur
													}
													minLength={6}
													maxLength={20}
													className='eyeicon inputBox '
													suffix={
														<img
															src={
																this.state
																	.showpassword
																	? require("../../images/eye-off.svg")
																	: require("../../images/eye.svg")
															}
															alt='eye'
															onClick={() =>
																this.showPass()
															}
														/>
													}
													style={{
														display: this.state
															.display,
													}}
												/>
											)}
										</FormItem>
										<FormItem>
											<div className='coinWidget'>
												<Button
													onClick={
														this.disabledButton
													}
													style={{
														display: this.state
															.display,
													}}
													className='coinButton profileApply'
													type='primary'
													htmlType='submit'
												>
													Apply
												</Button>
												<Button
													onClick={
														this.disabledButton
													}
													style={{
														display: this.state
															.display,
													}}
													className='coinButton profilecancel'
													type='primary'
													htmlType='submit'
												>
													<div className='profileText'>
														{" "}
														Cancel
													</div>
												</Button>
											</div>
										</FormItem>
									</Form>
								</div>
							</Col>
							<Col md={8}>
								<div className='paymentMethod'>
									<div className='basicInfo'>
										<div className='paymentNotification'>
											<div className='infoButton'>
												Payment Methods
											</div>

											<div> </div>
										</div>
										<Form onSubmit={this.handleSubmit}>
											<FormItem>
												{getFieldDecorator(
													"card",
													{}
												)(
													<Input
														placeholder='XXX XXX XXX-342'
														maxLength={20}
														className='inputBox'
														disabled={
															this.state.disabled
																? "disabled"
																: ""
														}
													/>
												)}
											</FormItem>
											<FormItem>
												{getFieldDecorator(
													"card",
													{}
												)(
													<Input
														type={"password"}
														placeholder='XXX XXX XXX-342'
														maxLength={20}
														className='inputBox'
														disabled={
															this.state.disabled
																? "disabled"
																: ""
														}
													/>
												)}
											</FormItem>

											<div
												onClick={this.showModal}
												className='watchListAddMore'
											>
												{" "}
												+ Add More{" "}
											</div>
										</Form>
										<WalletsAdd />
									</div>
								</div>
							</Col>
						</Row>
					</div>
				</div>
				<CardModal
					wrappedComponentRef={this.saveFormRef}
					visible={this.state.cardvisible}
					onCancel={this.handleCancel}
					onCreate={this.cardCreate}
				/>
			</React.Fragment>
		)
	}
}

export default Form.create()(NewProfile)
