import React, { Component } from "react"
import { Row, Col, Tabs, Card, Button, Icon } from "antd"
import { Line, Chart } from "react-chartjs-2"
import {
	NewsApiByCoin,
	SpecificCoinData,
	getOverallDataOfCoin,
} from "../../services/Cryptoapis"
import {
	addToWatchList,
	getWatchList,
	removeFromWatchList,
	profileGet,
} from "../../services/Api"
import NewsModal from "../Modals/NewsModal"
import "../../charts/Charts.scss"
import { TimeStampToDate } from "../../converters/timeConverters"
import { toast } from "react-toastify"
import zoom from "chartjs-plugin-zoom"
import Hammer from "hammerjs"
import { withRouter } from "react-router-dom"
import WidgetByCoin from "../../widgets/WidgetByCoin"
import WidgetTechnicalAnalysis from "../../widgets/WidgetTechnicalAnalysis"

const { Meta } = Card
const { TabPane } = Tabs

function callback(key) {
	console.log(key)
}

class ViewCoin extends Component {
	constructor(props) {
		super(props)
		this.state = {
			news: [],
			isBuyPaneOpen: false,
			isSellPaneOpen: false,
			coinsValues: "",
			coinStatus: "",
			coindata: {},
			overallCoinData: {},
			wallets: [],
		}

		this.showModal = (image, title, description, time, url) => {
			this.setState({
				visible: true,
				image: image,
				title: title,
				description: description,
				time: TimeStampToDate(time),
				link: url,
			})
		}

		this.buypage = (route) => {
			this.props.history.push({
				pathname: route,
				coin: this.props.location.coin,
			})
		}

		this.saveFormRef = (formRef) => (this.formRef = formRef)
		this.handleCancel = (e) =>
			this.setState({ visible: false, error: false })

		this.AddCoinToWatchList = async () => {
			const coin = this.props.location.coin
			const watchListCoin = {
				coin: coin,
			}
			const CoinToWatchList = await addToWatchList(watchListCoin)
			toast(CoinToWatchList.message)
			this.setState({
				coinStatus: "watchlist",
			})
		}

		this.removeCoinFromWatchList = async () => {
			const coin = this.props.location.coin
			const watchListCoin = {
				coin: coin,
			}
			const RemoveCoin = await removeFromWatchList(watchListCoin)
			toast(RemoveCoin.message)
			this.setState({
				coinStatus: "",
			})
		}
	}
	updateDimensions() {
		this.setState({
			height: window.innerHeight - 70,
			width: window.innerWidth,
		})
	}

	async getCoinNews() {
		const coin = this.props.location.coin
		const News = await NewsApiByCoin(coin)
		this.setState({
			news: News,
		})
	}

	async getSpecificCoinData() {
		const coin = this.props.location.coin
		const CoinData = await SpecificCoinData(coin)
		const overallcoindata = await getOverallDataOfCoin(coin)
		this.setState({
			coindata: CoinData,
			overallCoinData: overallcoindata.CoinInfo,
			coinsValues: CoinData.CurrentPrice,
		})

		console.log("overall", this.state.overallCoinData)
	}

	async watchListButton() {
		const coin = this.props.location.coin
		const watchListArray = await getWatchList()
		watchListArray.data.map((item) => {
			if (coin == item) {
				this.setState({
					coinStatus: "watchlist",
				})
			}
		})
	}
	componentDidMount() {
		this.getCoinNews()
		this.getSpecificCoinData()
		this.updateDimensions()
		window.addEventListener("resize", this.updateDimensions.bind(this))
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.updateDimensions.bind(this))
		Chart.plugins.register(zoom)
	}

	static defaultProps = {
		displayTitle: true,
		displayLegend: true,
		legendPosition: "right",
		location: "City",
	}
	render() {
		return (
			<React.Fragment>
				<div className='viewCoinPage'>
					<div className='coinHeader'>
						<div className='coinTitle'>
							<Icon
								onClick={() => this.props.history.goBack()}
								type='arrow-left'
								style={{ fontSize: 20 }}
							></Icon>
							<div className='coinName'>
								{this.state.overallCoinData.FullName}
							</div>
						</div>
						<div className='coinWidget'>
							<div className='coinTitle'>
								<Icon
									type='star'
									onClick={this.removeCoinFromWatchList}
									style={{
										fontSize: 20,
										color:
											this.state.coinStatus ===
											"watchlist"
												? "orange"
												: "blue",
									}}
									theme='filled'
								></Icon>
								<div
									onClick={this.AddCoinToWatchList}
									className='coinName'
								>
									{" "}
									Add to WatchList
								</div>
							</div>
							<Button
								onClick={() => this.buypage("/exchangebuy")}
								className='coinButton buy'
								type='primary'
							>
								Buy
							</Button>
							<Button
								onClick={() => this.buypage("/exchangesell")}
								className='coinButton sell'
								type='primary'
							>
								Sell
							</Button>
							<Button className='coinButton trade' type='primary'>
								Trade
							</Button>
						</div>
					</div>
					<div style={{ paddingTop: 30 }}>
						<Row gutter={15} justify='space-between' align='middle'>
							<Col
								span={12}
								style={{
									paddingTop: 10,
									paddingLeft: 10,
									paddingBottom: 10,
									paddingRight: 10,
								}}
								sm={24}
								md={{ span: 12 }}
								lg={{ span: 8 }}
							>
								<Card style={{ borderRadius: 14 }}>
									<div className='coinHeader'>
										<div>
											<h6 style={{ color: "#2d1582" }}>
												{" "}
												MarketCap
											</h6>
										</div>
										<div>
											<img
												style={{
													height: 30,
													width: 30,
													color: "#08c",
													align: "left",
												}}
												alt=''
												src={this.state.coindata.Image}
											></img>
										</div>
									</div>

									<div className='coinHeader'>
										<div>
											<h6
												className='textOverflow'
												style={{ color: "green" }}
											>
												${this.state.coindata.MarketCap}
											</h6>
										</div>
										<div></div>
									</div>
								</Card>
							</Col>
							<Col
								span={12}
								style={{
									paddingTop: 10,
									paddingLeft: 10,
									paddingBottom: 10,
									paddingRight: 10,
								}}
								sm={24}
								md={{ span: 12 }}
								lg={{ span: 8 }}
							>
								<Card style={{ borderRadius: 14 }}>
									<div className='coinHeader'>
										<div>
											<h6 style={{ color: "#2d1582" }}>
												{" "}
												Volume24Hour
											</h6>
										</div>

										<div>
											<img
												style={{
													height: 30,
													width: 30,
													color: "#08c",
													align: "left",
												}}
												alt=''
												src={this.state.coindata.Image}
											></img>
										</div>
									</div>
									<div className='coinHeader'>
										<div style={{ float: "left" }}>
											<h6 style={{ color: "green" }}>
												{
													this.state.coindata
														.Volume24hour
												}{" "}
												Coins
											</h6>
										</div>
									</div>
								</Card>
							</Col>
							<Col
								span={12}
								style={{
									paddingTop: 10,
									paddingLeft: 10,
									paddingBottom: 10,
									paddingRight: 10,
								}}
								sm={24}
								md={{ span: 12 }}
								lg={{ span: 8 }}
							>
								<Card style={{ borderRadius: 14 }}>
									<div className='coinHeader'>
										<div>
											<h6 style={{ color: "#2d1582" }}>
												{" "}
												CurrentPrice
											</h6>
										</div>
										<div>
											<img
												style={{
													height: 30,
													width: 30,
													color: "#08c",
													align: "left",
												}}
												alt=''
												src={this.state.coindata.Image}
											></img>
										</div>
									</div>
									<div className='coinHeader'>
										<div>
											<h6 style={{ color: "green" }}>
												$
												{
													this.state.coindata
														.CurrentPrice
												}
											</h6>
										</div>
										<div></div>
									</div>
								</Card>
							</Col>
						</Row>
					</div>

					<Tabs defaultActiveKey='1' onChange={callback}>
						<TabPane tab='Graphs' key='1'>
							<WidgetByCoin coin={this.props.location.coin} />
						</TabPane>

						<TabPane tab='News' key='2'>
							<Card
								title='News'
								bodyStyle={{ padding: 0 }}
								loading={false}
							>
								<Row
									gutter={15}
									justify='space-between'
									align='middle'
								>
									{this.state.news.map((item, key) => (
										<Col
											span={12}
											md={{ span: 12 }}
											lg={{ span: 8 }}
											style={{
												paddingTop: 15,
												marginTop: 10,
											}}
										>
											<Card
												hoverable
												cover={
													<img
														alt='example'
														src={item.imageurl}
													/>
												}
												actions={[
													<a
														onClick={() =>
															this.showModal(
																item.imageurl,
																item.title,
																item.body,
																item.published_on,
																item.url
															)
														}
													>
														{" "}
														Quick View{" "}
													</a>,
													<a
														href={item.url}
														target='_blank'
													>
														{" "}
														Read{" "}
													</a>,
												]}
											>
												<div
													style={{
														fontSize: 10,
														paddingRight: 5,
													}}
												>
													<Meta
														title={item.title}
														description={
															item.categories
														}
													/>
												</div>
											</Card>
										</Col>
									))}
								</Row>
							</Card>
						</TabPane>
						<TabPane tab='Technical Analysis' key='3'>
							<WidgetTechnicalAnalysis
								coin={this.props.location.coin}
							/>
						</TabPane>
					</Tabs>

					<NewsModal
						wrappedComponentRef={this.saveFormRef}
						visible={this.state.visible}
						image={this.state.image}
						topic={this.state.title}
						description={this.state.description}
						link={this.state.link}
						onCancel={this.handleCancel}
						time={this.state.time}
						onCreate={this.handleCreate}
					/>
				</div>
			</React.Fragment>
		)
	}
}

export default withRouter(ViewCoin)
