import { TimeStampToDate, TimeStampToTime } from "../converters/timeConverters"
import {
	BTC,
	ETH,
	XLM,
	LTC,
	BCH,
	BSV,
	XRP,
	XMR,
	NEO,
	EOS,
	BNB,
	ADA,
	TRX,
	MIOTA,
} from "./SparkLineApis"
import { getWatchList } from "./Api"

// function to get data for the minute graph
export async function MinuteDataApi(Coin) {
	let OverallData = {
		data1: [],
		data2: [],
	}
	try {
		var response = await fetch(
			`https://min-api.cryptocompare.com/data/v2/histominute?fsym=${Coin}&tsym=USD&limit=60`
		)
		let data = await response.json()
		let dataArray = data.Data.Data

		dataArray.map((item) => {
			OverallData.data1.push(TimeStampToTime(item.time))
			OverallData.data2.push(item.high)
			return null
		})
	} catch (error) {
		return error
	}
	return OverallData
}

// function to get data for the hour graph
export async function HourlyDataApi(Coin) {
	let OverallData = {
		data1: [],
		data2: [],
	}
	try {
		var response = await fetch(
			`https://min-api.cryptocompare.com/data/v2/histohour?fsym=${Coin}&tsym=USD&limit=60`
		)
		let data = await response.json()
		let dataArray = data.Data.Data
		dataArray.map((item, i) => {
			OverallData.data1.push(TimeStampToTime(item.time))
			OverallData.data2.push(item.high)
			return item
		})
	} catch (error) {
		return error
	}
	return OverallData
}

// function to get data for the daily graph
export async function DailyDataApi(Coin) {
	let OverallData = {
		data1: [],
		data2: [],
	}
	try {
		var response = await fetch(
			`https://min-api.cryptocompare.com/data/v2/histoday?fsym=${Coin}&tsym=USD&limit=60`
		)
		let data = await response.json()
		let dataArray = data.Data.Data
		dataArray.map((item, i) => {
			OverallData.data1.push(TimeStampToDate(item.time))
			OverallData.data2.push(item.high)
			return item
		})
	} catch (error) {
		return error
	}
	return OverallData
}

// 1hr - 24hr - 1W - 1M -1Y - All
//monthly API data
export async function MonthlyDataApi(Coin) {
	let OverallData = {
		data1: [],
		data2: [],
	}
	try {
		var response = await fetch(
			`https://min-api.cryptocompare.com/data/v2/histoday?fsym=${Coin}&tsym=USD&limit=500`
		)
		let data = await response.json()
		let dataArray = data.Data.Data
		dataArray.map((item, i) => {
			OverallData.data1.push(TimeStampToDate(item.time))
			OverallData.data2.push(item.high)
			return item
		})
	} catch (error) {
		return error
	}
	return OverallData
}

//yearly data APIs
export async function YearlyDataApi(Coin) {
	let OverallData = {
		data1: [],
		data2: [],
	}
	try {
		var response = await fetch(
			`https://min-api.cryptocompare.com/data/v2/histoday?fsym=${Coin}&tsym=USD&limit=1000`
		)
		let data = await response.json()
		let dataArray = data.Data.Data
		dataArray.map((item, i) => {
			OverallData.data1.push(TimeStampToDate(item.time))
			OverallData.data2.push(item.high)
			return item
		})
	} catch (error) {
		return error
	}
	return OverallData
}
//all time day
export async function AlltimeDataApi(Coin) {
	let OverallData = {
		data1: [],
		data2: [],
	}
	try {
		var response = await fetch(
			`https://min-api.cryptocompare.com/data/v2/histoday?fsym=${Coin}&tsym=USD&limit=2000`
		)
		let data = await response.json()
		let dataArray = data.Data.Data
		dataArray.map((item, i) => {
			OverallData.data1.push(TimeStampToDate(item.time))
			OverallData.data2.push(item.high)
			return item
		})
	} catch (error) {
		return error
	}
	return OverallData
}

///the news api
export async function NewsApi() {
	try {
		var response = await fetch(
			"https://min-api.cryptocompare.com/data/v2/news/?lang=EN"
		)
		let data = await response.json()
		let dataArray = data.Data
		return dataArray
	} catch (error) {
		return error
	}
}

//function to get cryptocurrencies currently on the home page
export async function CryptoCoinsOnHome() {
	let data2 = []
	let btc = await BTC()
	let eth = await ETH()
	let xlm = await XLM()
	let ltc = await LTC()
	let bch = await BCH()
	let bsv = await BSV()

	// try{
	var response = await fetch(process.env.REACT_APP_CRYPTOCOINS)
	let data = await response.json()
	let data1 = data.RAW
	try {
		Object.keys(data1).map((key, index) => {
			var sparklineData = []
			if (index === 0) {
				sparklineData = btc
			} else if (index === 1) {
				sparklineData = eth
			} else if (index === 2) {
				sparklineData = xlm
			} else if (index === 3) {
				sparklineData = ltc
			} else if (index === 4) {
				sparklineData = bch
			} else if (index === 5) {
				sparklineData = bsv
			}

			const livedata = {
				key: index,
				name: data1[key].USD.FROMSYMBOL,
				lastprice: data1[key].USD.PRICE,
				hourchange: data1[key].USD.CHANGE24HOUR,
				volume24hour:
					data1[
						key
					].USD.VOLUME24HOUR.toLocaleString(navigator.language, {
						minimumFractionDigits: 2,
					}) +
					" " +
					data1[key].USD.FROMSYMBOL,
				graphdata: sparklineData,
				icon:
					"https://www.cryptocompare.com/" + data1[key].USD.IMAGEURL,
			}

			data2.push(livedata)
		})
	} catch (error) {}

	return data2
}

//function to get cryptocurrencies currently on the home page
export async function SpecificCoinData(coin) {
	let data2 = {}
	try {
		var response = await fetch(
			`https://min-api.cryptocompare.com/data/pricemultifull?fsyms=${coin}&tsyms=USD`
		)
		let data = await response.json()
		let data1 = data.RAW

		Object.keys(data1).map((key, index) => {
			//  const livedata=

			const dataArray = {
				MarketCap: data1[
					key
				].USD.MKTCAP.toLocaleString(navigator.language, {
					minimumFractionDigits: 2,
				}),
				Volume24hour: data1[
					key
				].USD.VOLUME24HOUR.toLocaleString(navigator.language, {
					minimumFractionDigits: 2,
				}),
				Image:
					"https://www.cryptocompare.com/" + data1[key].USD.IMAGEURL,
				ChangePerHour: data1[
					key
				].USD.CHANGEHOUR.toLocaleString(navigator.language, {
					minimumFractionDigits: 2,
				}),
				CurrentPrice: data1[key].USD.PRICE,
				High24Hour: data1[
					key
				].USD.HIGH24HOUR.toLocaleString(navigator.language, {
					minimumFractionDigits: 2,
				}),
			}
			Object.assign(data2, dataArray)
		})
	} catch (error) {}
	return data2
}

//function to display coins on the homepage
export async function CryptoCoinsOnDashboard() {
	let data2 = []
	try {
		var response = await fetch(process.env.REACT_APP_DASHBOARDCRYPTOCOINS)
		let data = await response.json()
		let data1 = data.RAW

		let btc = await BTC()
		let eth = await ETH()
		let xlm = await XLM()
		let ltc = await LTC()
		let bch = await BCH()
		let bsv = await BSV()
		let xrp = await XRP()
		let xmr = await XMR()
		let neo = await NEO()
		let eos = await EOS()
		let bnb = await BNB()
		let ada = await ADA()
		let trx = await TRX()
		let miota = await MIOTA()

		Object.keys(data1).map((key, index) => {
			var sparklineData = []
			if (index === 0) {
				sparklineData = btc
			} else if (index === 1) {
				sparklineData = eth
			} else if (index === 2) {
				sparklineData = xlm
			} else if (index === 3) {
				sparklineData = ltc
			} else if (index === 4) {
				sparklineData = bch
			} else if (index === 5) {
				sparklineData = bsv
			} else if (index === 6) {
				sparklineData = bnb
			} else if (index === 7) {
				sparklineData = ada
			} else if (index === 8) {
				sparklineData = trx
			} else if (index === 9) {
				sparklineData = miota
			} else if (index === 10) {
				sparklineData = bsv
			} else if (index === 11) {
				sparklineData = eos
			} else if (index === 12) {
				sparklineData = xmr
			} else if (index === 13) {
				sparklineData = xrp
			} else if (index === 14) {
				sparklineData = neo
			}

			const livedata = {
				key: index,
				name: data1[key].USD.FROMSYMBOL,
				lastprice: data1[key].USD.PRICE,
				hourchange: data1[key].USD.CHANGE24HOUR,
				marketevariation:
					"$" +
					data1[key].USD.MKTCAP.toLocaleString(navigator.language, {
						minimumFractionDigits: 2,
					}),
				visibility: "hidden",
				marketcapitalisation: data1[
					key
				].USD.MKTCAP.toLocaleString(navigator.language, {
					minimumFractionDigits: 2,
				}),
				volume24hour:
					data1[
						key
					].USD.VOLUME24HOUR.toLocaleString(navigator.language, {
						minimumFractionDigits: 2,
					}) +
					" " +
					data1[key].USD.FROMSYMBOL,
				graphdata: sparklineData,
				icon:
					"https://www.cryptocompare.com/" + data1[key].USD.IMAGEURL,
			}

			data2.push(livedata)
		})
	} catch (error) {}
	return data2
}

export async function CoinsOnTrade() {
	let data2 = []
	try {
		var response = await fetch(process.env.REACT_APP_DASHBOARDCRYPTOCOINS)
		let data = await response.json()
		let data1 = data.RAW
		Object.keys(data1).map((key, index) => {
			data2.push({
				key: index,
				name: data1[key].USD.FROMSYMBOL,
				lastprice: +data1[key].USD.PRICE,
				icon:
					"https://www.cryptocompare.com/" + data1[key].USD.IMAGEURL,
			})
		})
	} catch (error) {}
	return data2
}

//function to display coins by Volume
export async function CryproCoinsByVolume() {
	let data2 = []
	try {
		var response = await fetch(
			"https://min-api.cryptocompare.com/data/top/totalvolfull?limit=20&tsym=USD"
		)
		let data = await response.json()
		let data1 = data.Data

		data1.map((item, i) => {
			data2.push({
				key: i,
				name: item.CoinInfo.FullName,
				// symbol:data1[key].USD.FROMSYMBOL,
				lastprice:
					"$" +
					item.RAW.USD.PRICE.toLocaleString(navigator.language, {
						minimumFractionDigits: 2,
					}),
				hourchange: item.RAW.USD.CHANGE24HOUR,
				volumehour:
					"$" +
					item.RAW.USD.VOLUMEHOUR.toLocaleString(navigator.language, {
						minimumFractionDigits: 2,
					}),
				volume24hour: item.RAW.USD.VOLUME24HOUR.toLocaleString(
					navigator.language,
					{ minimumFractionDigits: 2 }
				),
				icon: "https://www.cryptocompare.com/" + item.CoinInfo.ImageUrl,
			})
		})
	} catch (error) {}
	return data2
}

//function to get news by coin
export async function NewsApiByCoin(Coin) {
	try {
		var response = await fetch(
			`https://min-api.cryptocompare.com/data/v2/news/?categories=${Coin},regulation`
		)
		let data = await response.json()
		let dataArray = data.Data
		return dataArray
	} catch (error) {
		return error
	}
}

export async function getWatchlistCoins() {
	const watchList = await getWatchList()

	const watchlistCoins = watchList.data.join()

	let data2 = []
	try {
		var response = await fetch(
			`https://min-api.cryptocompare.com/data/pricemultifull?fsyms=${watchlistCoins}&tsyms=USD`
		)
		let data = await response.json()
		let data1 = data.RAW

		Object.keys(data1).map((key, index) => {
			data2.push({
				key: index,
				name: data1[key].USD.FROMSYMBOL,
				lastprice:
					"$" +
					data1[key].USD.PRICE.toLocaleString(navigator.language, {
						minimumFractionDigits: 2,
					}),
				visibility: "hidden",
				marketcapitalisation: data1[
					key
				].USD.MKTCAP.toLocaleString(navigator.language, {
					minimumFractionDigits: 2,
				}),
				hourchange: data1[key].USD.CHANGE24HOUR,
				volume24hour:
					data1[
						key
					].USD.VOLUME24HOUR.toLocaleString(navigator.language, {
						minimumFractionDigits: 2,
					}) +
					" " +
					data1[key].USD.FROMSYMBOL,
				icon:
					"https://www.cryptocompare.com/" + data1[key].USD.IMAGEURL,
			})
		})
	} catch (error) {}
	return data2
}

export async function getOverallDataOfCoin(coin) {
	let data2 = {}
	try {
		var response = await fetch(
			`https://min-api.cryptocompare.com/data/top/exchanges/full?fsym=${coin}&tsym=USD`
		)
		let data = await response.json()
		let data1 = data.Data
		console.log("direct", data1)
		const dataArray = {
			CoinInfo: data1.CoinInfo,
			AggregatedData: data1.AggregatedData,
			Exchanges: data1.Exchanges,
		}
		Object.assign(data2, dataArray)
	} catch (error) {}

	// console.log("OverallCOINDATA",data2)
	return data2
}
