import React, { Component } from "react"
import { Row, Col, Card, Icon, Form, Select } from "antd"
import CoinSelect from "../Modals/CoinSelect"
import { Link } from "react-router-dom"
import CoinBuyTable from "../tables/CoinBuyTable"
import UserSellingList from "../tables/UserSellingList"
import { CoinsOnTrade } from "../../services/Cryptoapis"
import { profileGet, addSeller, getUsersListedCoins } from "../../services/Api"
import { Button } from "react-bootstrap"
import { toast } from "react-toastify"
const { Option } = Select

class ExchangeSell extends Component {
	constructor(props) {
		super(props)
		this.state = {
			coinsValues: "",
			visible: false,
			selectedcoins: [],
			sellinglist: [],
			profile: {},
			wallets: [],
			currentcoin: "",
			coinQuantity: "",
			coinName: "",
		}

		this.handleSelectChange = (value) => {
			console.log("VALUE", value)
			const coininfo = JSON.parse(value)
			this.setState({
				currentcoin: coininfo.price,
				coinsValues: coininfo.price,
				coinName: coininfo.name,
			})
		}
		this.handleWalletChange = (value) => {
			const walletInfo = JSON.parse(value)
			this.setState({
				walletName: walletInfo.name,
				walletBalance: walletInfo.balance,
			})
		}

		this.handleConverterChange = (e) => {
			console.log("handle change called", e.target.value)
			console.log("coin", parseInt(this.state.coinsValues))
			if (e.target.value != "") {
				const value = parseInt(this.state.coinsValues) * e.target.value

				console.log(value)
				this.setState({
					currentcoin: value,
					coinQuantity: e.target.value,
				})
			}
		}

		this.submitSale = async () => {
			const coinSale = {
				sellerName: this.state.profile.fullName,
				coinName: this.state.coinName,
				coinQty: this.state.coinQuantity,
				amount: this.state.currentcoin,
			}
			const addCoinSeller = await addSeller(coinSale)
			console.log(addCoinSeller)
			toast(addCoinSeller.message)
		}
	}

	async getListedCoins() {
		let UsersListedCoins = await getUsersListedCoins()
		console.log("ListedCoins", UsersListedCoins)
		if (UsersListedCoins.message === "Success") {
			this.setState({
				sellinglist: UsersListedCoins.data,
			})
		} else {
			// toast(UsersListedCoins.message)
		}
	}

	async componentDidMount() {
		this.getListedCoins()
		let coinToSelect = await CoinsOnTrade()
		const Profile = await profileGet()
		this.setState({
			selectedcoins: coinToSelect,
			wallets: Profile.data.wallets,
			profile: Profile.data,
		})
		console.log(this.state.profile)
	}

	render() {
		const props = this.props

		const { getFieldDecorator } = props.form
		return (
			<React.Fragment>
				<div className='buysellHeader'>
					<span>Sell</span>
					<div className='buysellButton'>
						<Link to='/exchangebuy'>
							{" "}
							<Button className='buyButtontab'>Buy</Button>
						</Link>
						<Link to='/exchangesell'>
							<Button className='sellButtontab activeButton'>
								Sell
							</Button>
						</Link>
					</div>
				</div>
				<Col sm={24} md={10}>
					<Row>
						<div className='totalHoldings buyButtons'>
							<div className='selectCoin'>
								<div className='buyContentText'>
									<div className='paymentHeader'>
										Select Coin
									</div>
								</div>
								<Form
									labelCol={{ span: 5 }}
									wrapperCol={{ span: 12 }}
									onSubmit={this.handleSubmit}
								>
									<Form.Item>
										{getFieldDecorator("coin", {
											rules: [
												{
													required: true,
													message:
														"Please select  coins!",
												},
											],
										})(
											<Select
												onChange={
													this.handleSelectChange
												}
											>
												{this.state.selectedcoins.map(
													(item, key) => (
														<Option
															value={JSON.stringify(
																{
																	name:
																		item.name,
																	price:
																		item.lastprice,
																}
															)}
														>
															<img
																style={{
																	height: 30,
																	width: 30,
																	margin: 10,
																}}
																src={item.icon}
															></img>
															{item.name}
														</Option>
													)
												)}
											</Select>
										)}
									</Form.Item>
								</Form>
							</div>

							<div className='convertorHeader'>Enter Amount</div>
							<div className='convertorSection'>
								<input
									type='number'
									onChange={(text) =>
										this.handleConverterChange(text)
									}
								></input>
								<div>
									{" "}
									<Icon
										style={{ fontSize: "18px" }}
										type='swap'
									/>
								</div>
								<input
									type='text'
									value={"$" + this.state.currentcoin}
								></input>
							</div>

							<div className='selectCoin'>
								<div className='buySectionPaymentSection'>
									<div className='buyContentText'>
										<div className='paymentHeader'>
											Payment Method
										</div>
									</div>
									<Form
										labelCol={{ span: 5 }}
										wrapperCol={{ span: 12 }}
										onSubmit={this.handleSubmit}
									>
										<Form.Item>
											{getFieldDecorator("wallet", {
												rules: [
													{
														required: true,
														message:
															"Please select  coins!",
													},
												],
											})(
												<Select
													onChange={
														this.handleWalletChange
													}
												>
													{this.state.wallets.map(
														(item, key) => (
															<Option
																value={JSON.stringify(
																	{
																		name:
																			item.walletName,
																		price:
																			item.walletAddress,
																		balance:
																			item.balance,
																	}
																)}
															>
																{
																	item.walletName
																}
															</Option>
														)
													)}
												</Select>
											)}
										</Form.Item>
									</Form>
								</div>
							</div>
							<div className='buttonSectionInSell'>
								<Button
									onClick={this.submitSale}
									className='payButton'
								>
									Sell
								</Button>
							</div>
						</div>
					</Row>
				</Col>

				<Col sm={24} md={14}>
					<div className='coinTable'>
						<div className='totalHoldingsTitle'>
							My Selling List
						</div>
						<div className='transactionTable'>
							<UserSellingList data={this.state.sellinglist} />
						</div>
					</div>
				</Col>
			</React.Fragment>
		)
	}
}

export default Form.create()(ExchangeSell)
