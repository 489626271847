import React, { Component } from "react"
import {
	Table,
	ToggleButtonGroup,
	ButtonToolbar,
	ToggleButton,
	InputGroup,
	FormControl,
	Button,
} from "react-bootstrap"
import { Card, Tabs, Tooltip, Popconfirm, Icon, Popover } from "antd"
import TradeModal from "../Modals/tradeModal"
import { getWatchlistCoins } from "../../services/Cryptoapis"
import { Sparklines, SparklinesLine, SparklinesSpots } from "react-sparklines"
import { withRouter, Link } from "react-router-dom"
import { toast } from "react-toastify"

const { TabPane } = Tabs
function callback(key) {
	console.log(key)
}
class WatchListTable extends Component {
	constructor(props) {
		super(props)
		this.state = {
			cryptodata: [],
			visible: false,
			popover: false,
			visibility: "hidden",
			search: "",
			coinStatus: "",
		}

		this.viewCoin = () => {
			props.history.push("/viewcoins")
		}

		this.getCryptoData = async () => {
			let data = await getWatchlistCoins()
			this.setState({
				cryptodata: data,
			})
		}
		this.otherpage = (text) => {
			this.props.history.push({
				pathname: "/viewcoin",
				coin: text,
			})
		}
		this.showModal = () => {
			this.setState({ visible: true })
		}

		this.showTradingButtons = (key, visible) => {
			let allcoins = this.state.cryptodata
			for (var i = 0; i < allcoins.length; i++) {
				if (allcoins[i].key === key) {
					allcoins[i].visibility = visible
				}
			}
			this.setState({
				cryptodata: allcoins,
			})
		}
		this.buypage = (route) => {
			this.props.history.push({
				pathname: route,
				coin: this.props.location.coin,
			})
		}
		this.saveFormRef = (formRef) => (this.formRef = formRef)
		this.handleCancel = (e) =>
			this.setState({
				visible: false,
				error: false,
				paymentModalVisible: false,
			})
		this.handleCreate = () => {
			const form = this.formRef.props.form
			form.validateFields(async (err, values) => {
				let from = values.from
				let to = values.to
			})
		}

		this.handleVisibleChange = () => {
			this.setState({ popover: true })
		}
	}
	handleSearch(event) {
		console.log(event.target.value)
		this.setState({
			search: event.target.value.substr(0, 20),
		})
	}

	componentDidMount() {
		this.getCryptoData()
		this.intervalID = setInterval(this.getCryptoData.bind(this), 5000)
	}

	componentWillUnmount() {
		clearInterval(this.intervalID)
	}

	render() {
		const total = this.state.cryptodata.length
		console.log("COINDATA", this.state.cryptodata)
		let filteredCoins = this.state.cryptodata.filter((coin) => {
			return coin.name.toLowerCase().indexOf(this.state.search) !== -1
		})
		return (
			<React.Fragment>
				<div className='exchangeHeader'>
					<div className='PorfolioHeader'> WatchList</div>

					<div className='totalHoldingsTitle'>
						<div className='SearchBar'>
							<div className='SearchBarContents'>
								<InputGroup className='mb-3'>
									<FormControl
										placeholder='Search'
										value={this.state.search}
										onChange={this.handleSearch.bind(this)}
										aria-label='Amount (to the nearest dollar)'
									/>
									<InputGroup.Append>
										<InputGroup.Text>
											<Icon
												style={{ fontSize: 20 }}
												type='search'
											></Icon>
										</InputGroup.Text>
									</InputGroup.Append>
								</InputGroup>
							</div>
							<div className='coinWatchlistHeader'>
								<div className='buysellButton'>
									<Link to='/coins'>
										{" "}
										<Button className='buyButtontab'>
											All
										</Button>
									</Link>
									<Link to='/watchlist'>
										<Button className='sellButtontab activeButton'>
											WatchList
										</Button>
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>

				<Card title='' bodyStyle={{ padding: 0 }} loading={false}>
					<div className='table-responsive'>
						<Table responsive='lg' borderless>
							<thead className='tableHead'>
								<tr>
									<th className='tabbleWidthIcon'></th>
									{/* <th className="tableWidth">Graph</th> */}
									<th className='tableWidth'>Name</th>
									<th className='tableWidth'>Last Price</th>
									<th className='tableWidth'>
										24hour Change
									</th>
									<th className='tableWidth'>Market Cap </th>
									<th className='tableWidth'>
										Volume 24 Hour
									</th>
									<th className='tableWidth'> </th>
									{/* marketcapitalisation */}
								</tr>
							</thead>
							{filteredCoins.map((coin, index) => (
								<tbody>
									<tr
										className='tableRowPointer'
										onMouseLeave={() =>
											this.showTradingButtons(
												coin.key,
												"hidden"
											)
										}
										onMouseEnter={() =>
											this.showTradingButtons(
												coin.key,
												"visible"
											)
										}
									>
										<td
											onClick={() =>
												this.otherpage(coin.name)
											}
										>
											<img
												style={{
													height: 30,
													width: 30,
												}}
												alt=''
												src={coin.icon}
											></img>
										</td>
					
										<td
											onClick={() =>
												this.otherpage(coin.name)
											}
										>
											{coin.name}
										</td>
										<td
											onClick={() =>
												this.otherpage(coin.name)
											}
											className='greenColor'
										>
											{coin.lastprice}
										</td>
										<td
											onClick={() =>
												this.otherpage(coin.name)
											}
											style={{
												color:
													coin.hourchange > 1
														? "green"
														: "red",
											}}
										>
											$
											{coin.hourchange.toLocaleString(
												navigator.language,
												{ minimumFractionDigits: 2 }
											)}
										</td>

										<td
											onClick={() =>
												this.otherpage(coin.name)
											}
											className='greenColor'
										>
											{coin.marketcapitalisation}
										</td>
										<td
											onClick={() =>
												this.otherpage(coin.name)
											}
											className='greenColor'
										>
											{coin.volume24hour}
										</td>

										<td>
											<div className='coinWidget'>
												<div
													style={{
														visibility:
															coin.visibility,
													}}
												>
													<Button
														onClick={() =>
															this.buypage(
																"/exchangebuy"
															)
														}
														className='coinButton buy'
														type='primary'
													>
														Buy
													</Button>
													<Button
														onClick={() =>
															this.buypage(
																"/exchangesell"
															)
														}
														className='coinButton sell'
														type='primary'
													>
														Sell
													</Button>
												</div>
											</div>
											
										</td>
						
									</tr>
								</tbody>
							))}
						</Table>
					</div>
				</Card>

				<TradeModal
					wrappedComponentRef={this.saveFormRef}
					visible={this.state.visible}
					onCancel={this.handleCancel}
					onCreate={this.handleCreate}
				/>
			</React.Fragment>
		)
	}
}
export default withRouter(WatchListTable)
