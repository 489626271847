import React, { Component } from "react"
import { Row, Col } from "antd"
import { CryptoCoinsOnHome } from "../../services/Cryptoapis"
import Ticker from "react-ticker"
class HomeCoinStrip extends Component {
	constructor() {
		super()
		this.state = {
			cryptodata: [],
		}
	}

	async getCryptoData() {
		let data = await CryptoCoinsOnHome()

		this.setState({
			cryptodata: data,
		})
	}

	async componentDidMount() {
		this.getCryptoData()
		this.intervalID = setInterval(this.getCryptoData.bind(this), 5000)
	}

	componentWillUnmount() {
		clearInterval(this.intervalID)
	}

	render() {
		return (
			<React.Fragment>
				<Row className='coinsInline'>
					<Ticker>
						<div className='tableCenter'>
							{this.state.cryptodata.map((item) => (
								<Col md={4}>
									<div className='coinInline'>
										{" "}
										{item.name}{" "}
										<span
											style={{
												color:
													item.hourchange > 1
														? "green"
														: "red",
											}}
										>
											${" "}
											{item.hourchange.toLocaleString(
												navigator.language,
												{ minimumFractionDigits: 2 }
											)}{" "}
										</span>
									</div>
								</Col>
							))}
						</div>
					</Ticker>
				</Row>
			</React.Fragment>
		)
	}
}

export default HomeCoinStrip
