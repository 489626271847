import React, { Component } from "react"
import { Icon, Card, Statistic, Col, Row } from "antd"

class ViewTransaction extends Component {
	render() {
		return (
			<div style={{ background: "#ECECEC", padding: "30px" }}>
				<Row gutter={16}>
					<Col span={8}>
						<Card
							title='Transaction  Amount'
							bordered={false}
							style={{ color: "red" }}
						>
							$1000
						</Card>
					</Col>
					<Col span={8}>
						<Card
							title='Coin Amount'
							style={{ color: "green" }}
							bordered={false}
						>
							0.2356
						</Card>
					</Col>
					<Col span={8}>
						<Card
							title='Transaction Type'
							style={{ color: "green" }}
							bordered={false}
						>
							Buy
						</Card>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={8}>
						<Card>
							<Statistic
								title='Investment Profit % Increase'
								value={11.28}
								precision={2}
								valueStyle={{ color: "#3f8600" }}
								prefix={<Icon type='arrow-up' />}
								suffix='%'
							/>
						</Card>
					</Col>
					<Col span={8}>
						<Card>
							<Statistic
								title='Investment % Loss'
								value={9.3}
								precision={2}
								valueStyle={{ color: "#cf1322" }}
								prefix={<Icon type='arrow-down' />}
								suffix='%'
							/>
						</Card>
					</Col>
				</Row>
			</div>
		)
	}
}

export default ViewTransaction
