import React, { Component } from "react"
import { Link, NavLink } from "react-router-dom"
import { toast } from "react-toastify"
import { register } from "../services/Api"
import "./signing.scss"
toast.configure()

class SignUpScreen extends Component {
	constructor() {
		super()
		this.state = {
			email: "",
			password: "",
			name: "",
			hasAgreed: false,
		}

		this.handleChange = this.handleChange.bind(this)
		this.handleSubmit = this.handleSubmit.bind(this)
	}

	handleChange(e) {
		let target = e.target
		let value = target.type === "checkbox" ? target.checked : target.value
		let name = target.name

		this.setState({
			[name]: value,
		})
	}

	async handleSubmit(e) {
		e.preventDefault()

		console.log("The form was submitted with the following data:")
		console.log(this.state)

		const auth1 = {
			fullName: this.state.name,
			email: this.state.email,
			password: this.state.password,
			redirectLink: "https://einsteinexchange.io/signin",
		}
		const RegisterValue = await register(auth1)

		toast(RegisterValue.message, { type: "success" })
		this.props.history.push("/signin")
	}

	render() {
		return (
			<div className='App'>
				<div className='App__Aside'>
					<h1>EinsteinExchange</h1>
				</div>

				<div className='App__Form'>
					<div className='PageSwitcher'>
						<NavLink
							to='/signin'
							activeClassName='PageSwitcher__Item--Active'
							className='PageSwitcher__Item'
						>
							Sign In
						</NavLink>
						<NavLink
							exact
							to='signup'
							activeClassName='PageSwitcher__Item--Active'
							className='PageSwitcher__Item'
						>
							Sign Up
						</NavLink>
					</div>

					<div className='FormCenter'>
						<form
							onSubmit={this.handleSubmit}
							className='FormFields'
						>
							<div className='FormField'>
								<label
									className='FormField__Label'
									htmlFor='name'
								>
									Full Name
								</label>
								<input
									type='text'
									id='name'
									className='FormField__Input'
									placeholder='Enter your full name'
									name='name'
									onChange={this.handleChange}
								/>
							</div>
							<div className='FormField'>
								<label
									className='FormField__Label'
									htmlFor='password'
								>
									Password
								</label>
								<input
									type='password'
									id='password'
									className='FormField__Input'
									placeholder='Enter your password'
									name='password'
									onChange={this.handleChange}
								/>
							</div>
							<div className='FormField'>
								<label
									className='FormField__Label'
									htmlFor='email'
								>
									E-Mail Address
								</label>
								<input
									type='email'
									id='email'
									className='FormField__Input'
									placeholder='Enter your email'
									name='email'
									onChange={this.handleChange}
								/>
							</div>
							​
							{/* <div className="FormField">
                <label className="FormField__CheckboxLabel">
                    <input className="FormField__Checkbox" type="checkbox" name="hasAgreed" value={this.state.hasAgreed} onChange={this.handleChange} /> I agree all statements in <a href="" className="FormField__TermsLink">terms of service</a>
                </label>
              </div> */}
							​
							<div className='FormField'>
								<button className='FormField__Button mr-20'>
									Sign Up
								</button>{" "}
								<Link to='/signin' className='FormField__Link'>
									I'm already member
								</Link>
							</div>
						</form>
						<div style={{ align: "center" }}>
							<a>
								{" "}
								<Link to='/home' className='FormField__Link'>
									{" "}
									Click go to Home Page{" "}
								</Link>
							</a>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
export default SignUpScreen
