import React, { Component } from "react"
import { Button } from "antd"
import { Table } from "react-bootstrap"

import { withRouter } from "react-router-dom"

class CoinBuyTable extends Component {
	constructor(props) {
		super(props)
		this.state = { cryptodata: [] }

		this.buyCoin = (
			coinName,
			seller,
			quantity,
			amount,
			sellerUserid,
			sellerid
		) => {
			this.props.history.push({
				pathname: "/buycoin",
				coin: {
					name: coinName,
					seller: seller,
					quantity: quantity,
					amount: amount,
					sellerUserid: sellerUserid,
					sellerid: sellerid,
				},
			})
		}
	}

	componentDidMount() {}
	render() {
		return (
			<div>
				<Table responsive='lg' borderless>
					<thead className='tableHead'>
						<tr>
							<th className='tabbleWidthIcon'>#</th>
							<th className='coinNumberWidth'>When</th>
							<th className='tableWidth'>Coin</th>
							<th className='tableWidth'>Seller</th>
							<th className='coinNumberWidth'>No Of Coins </th>
							<th className='tableWidth'>Amount</th>
							<th className='coinNumberWidth'>Status</th>
							<th className='tableWidth'>Transact</th>
						</tr>
					</thead>

					{this.state.cryptodata.map((coin, index) => (
						<tbody>
							<tr>
								<td>{index}</td>
								<td>{coin.timeago}</td>
								<td>{coin.coinName} </td>
								<td>{coin.sellerName}</td>
								<td>{coin.coinQty}</td>
								<td>${coin.amount}</td>
								<td style={{ color: "green" }}>
									{coin.status}
								</td>
								<td>
									<Button
										onClick={() =>
											this.buyCoin(
												coin.coinName,
												coin.sellerName,
												coin.coinQty,
												coin.amount,
												coin.userId,
												coin._id
											)
										}
										className='coinButton buy'
										type='primary'
									>
										Buy
									</Button>
								</td>
							</tr>
						</tbody>
					))}
				</Table>
			</div>
		)
	}
}
export default withRouter(CoinBuyTable)
