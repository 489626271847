import React, { Component } from "react"
import { Card, Row, Col } from "antd"
import { NewsApi } from "../../services/Cryptoapis"
import { Link } from "react-router-dom"
import { trackPromise } from "react-promise-tracker"

const { Meta } = Card

class DashboardNews extends Component {
	constructor(props) {
		super(props)
		this.state = {
			short: "",
			mounted: false,
			image: "",
			title: "",
			description: "",
			time: "",
			link: "",
			news: [],
		}
	}

	async componentDidMount() {
		trackPromise(
			this.getNews().then((result) => {
				console.log("Result", result)
				this.setState({
					news: result,
				})
			})
		)
	}

	async getNews() {
		const News = await NewsApi()
		return News
	}

	componentWillUnmount() {
		clearInterval(this.intervalID)
	}

	render() {
		return (
			<div>
				<Row justify='space-between' align='middle'>
					{this.state.news.slice(0, 2).map((item, key) => (
						<Col md={12} style={{ paddingTop: 5 }}>
							<div className='walletTitle'>
								<img
									style={{ height: 50, width: 50 }}
									src={item.imageurl}
								></img>
								<div className='newsName'>
									<Link to='/news'> {item.title}</Link>
								</div>
							</div>
						</Col>
					))}
				</Row>
				<div className='viewAllNews'>
					{" "}
					<Link to='/news'>View All</Link>
				</div>
			</div>
		)
	}
}
export default DashboardNews
