export async function BTC() {
	let BTCDATA = []

	try {
		var response = await fetch(
			`https://min-api.cryptocompare.com/data/v2/histominute?fsym=BTC&tsym=USD&limit=40`
		)
		let data = await response.json()
		let dataArray = data.Data.Data
		dataArray.map((item) => {
			BTCDATA.push(item.high)
			return null
		})
	} catch (error) {
		return error
	}
	return BTCDATA
}

export async function ETH() {
	let ETHDATA = []
	try {
		var response = await fetch(
			`https://min-api.cryptocompare.com/data/v2/histominute?fsym=ETH&tsym=USD&limit=40`
		)
		let data = await response.json()
		let dataArray = data.Data.Data
		dataArray.map((item) => {
			ETHDATA.push(item.high)
			return null
		})
	} catch (error) {
		return error
	}
	return ETHDATA
}

export async function XLM() {
	let XLMDATA = []
	try {
		var response = await fetch(
			`https://min-api.cryptocompare.com/data/v2/histominute?fsym=XLM&tsym=USD&limit=40`
		)
		let data = await response.json()
		let dataArray = data.Data.Data
		dataArray.map((item) => {
			XLMDATA.push(item.high)
			return null
		})
	} catch (error) {
		return error
	}
	return XLMDATA
}

export async function LTC() {
	let LTCDATA = []
	try {
		var response = await fetch(
			`https://min-api.cryptocompare.com/data/v2/histominute?fsym=LTC&tsym=USD&limit=40`
		)
		let data = await response.json()
		let dataArray = data.Data.Data
		dataArray.map((item) => {
			LTCDATA.push(item.high)
			return null
		})
	} catch (error) {
		return error
	}
	return LTCDATA
}

export async function BCH() {
	let BCHDATA = []
	try {
		var response = await fetch(
			`https://min-api.cryptocompare.com/data/v2/histominute?fsym=BCH&tsym=USD&limit=40`
		)
		let data = await response.json()
		let dataArray = data.Data.Data
		dataArray.map((item) => {
			BCHDATA.push(item.high)
			return null
		})
	} catch (error) {
		return error
	}
	return BCHDATA
}

export async function BSV() {
	let BSVDATA = []
	try {
		var response = await fetch(
			`https://min-api.cryptocompare.com/data/v2/histominute?fsym=BSV&tsym=USD&limit=40`
		)
		let data = await response.json()
		let dataArray = data.Data.Data

		dataArray.map((item) => {
			BSVDATA.push(item.high)
			return null
		})
	} catch (error) {
		return error
	}
	return BSVDATA
}

// XRP,XMR,NEO,EOS,BSV,BNB,ADA,TRX,MIOTA
export async function XRP() {
	let XRPDATA = []
	try {
		var response = await fetch(
			`https://min-api.cryptocompare.com/data/v2/histominute?fsym=XRP&tsym=USD&limit=40`
		)
		let data = await response.json()
		let dataArray = data.Data.Data
		dataArray.map((item) => {
			XRPDATA.push(item.high)
			return null
		})
	} catch (error) {
		return error
	}
	return XRPDATA
}

export async function XMR() {
	let XMRDATA = []
	try {
		var response = await fetch(
			`https://min-api.cryptocompare.com/data/v2/histominute?fsym=XMR&tsym=USD&limit=40`
		)
		let data = await response.json()
		let dataArray = data.Data.Data

		dataArray.map((item) => {
			XMRDATA.push(item.high)
			return null
		})
	} catch (error) {
		return error
	}
	return XMRDATA
}

export async function NEO() {
	let NEODATA = []
	try {
		var response = await fetch(
			`https://min-api.cryptocompare.com/data/v2/histominute?fsym=NEO&tsym=USD&limit=40`
		)
		let data = await response.json()
		let dataArray = data.Data.Data
		dataArray.map((item) => {
			NEODATA.push(item.high)
			return null
		})
	} catch (error) {
		return error
	}
	return NEODATA
}

export async function EOS() {
	let EOSDATA = []
	try {
		var response = await fetch(
			`https://min-api.cryptocompare.com/data/v2/histominute?fsym=EOS&tsym=USD&limit=40`
		)
		let data = await response.json()
		let dataArray = data.Data.Data
		dataArray.map((item) => {
			EOSDATA.push(item.high)
			return null
		})
	} catch (error) {
		return error
	}
	return EOSDATA
}

export async function BNB() {
	let BNBDATA = []
	try {
		var response = await fetch(
			`https://min-api.cryptocompare.com/data/v2/histominute?fsym=BNB&tsym=USD&limit=40`
		)
		let data = await response.json()
		let dataArray = data.Data.Data
		dataArray.map((item) => {
			BNBDATA.push(item.high)
			return null
		})
	} catch (error) {
		return error
	}
	return BNBDATA
}

export async function ADA() {
	let ADADATA = []
	try {
		var response = await fetch(
			`https://min-api.cryptocompare.com/data/v2/histominute?fsym=ADA&tsym=USD&limit=40`
		)
		let data = await response.json()
		let dataArray = data.Data.Data
		dataArray.map((item) => {
			ADADATA.push(item.high)
			return null
		})
	} catch (error) {
		return error
	}
	return ADADATA
}

export async function TRX() {
	let TRXDATA = []
	try {
		var response = await fetch(
			`https://min-api.cryptocompare.com/data/v2/histominute?fsym=TRX&tsym=USD&limit=40`
		)
		let data = await response.json()
		let dataArray = data.Data.Data
		dataArray.map((item) => {
			TRXDATA.push(item.high)
			return null
		})
	} catch (error) {
		return error
	}
	return TRXDATA
}

//MIOTA

export async function MIOTA() {
	let MIOTADATA = []
	try {
		var response = await fetch(
			`https://min-api.cryptocompare.com/data/v2/histominute?fsym=MIOTA&tsym=USD&limit=40`
		)
		let data = await response.json()
		let dataArray = data.Data.Data
		dataArray.map((item) => {
			MIOTADATA.push(item.high)
			return null
		})
	} catch (error) {
		return error
	}
	return MIOTADATA
}
