import React, { Component } from "react"
import { Bar, Line, Pie } from "react-chartjs-2"
import { MinuteDataApi } from "../services/Cryptoapis"
import { TimeStampToDate } from "../converters/timeConverters"

const data = [1, 3, 4, 5, 6, 7, 8, 9]
class Rechart extends Component {
	constructor(props) {
		super(props)
		this.state = {
			chartDataMinute: {
				labels: [],
				datasets: [],
			},
		}
	}

	async getMinuteData() {
		const coin = this.props.location.coin
		let dailydata = await MinuteDataApi("BCH")
		this.setState({
			chartDataMinute: {
				labels: dailydata.data1,
				datasets: [
					{
						label: this.props.location.coin,
						data: dailydata.data2,
						borderColor: "rgba(204,149,192, 1)",
						backgroundColor: "rgba(204,149,192, 0.4)",
					},
				],
			},
		})
	}
	async componentDidMount() {
		this.getMinuteData()
	}

	static defaultProps = {
		displayTitle: true,
		displayLegend: true,
		legendPosition: "right",
		location: "City",
	}

	render() {
		return (
			<div className='chart'>
				<Line
					data={this.state.chartDataMinute}
					options={{
						title: {
							display: this.props.displayTitle,

							fontSize: 25,
						},
						legend: {
							display: this.props.displayLegend,
							position: this.props.legendPosition,
						},
					}}
				/>
			</div>
		)
	}
}

export default Rechart
