import React, { Component } from "react"
import SlidingPane from "react-sliding-pane"
import { profileGet } from "../../services/Api"
import { Button, Icon, Checkbox } from "antd"
import "./Sidepane.scss"

class SidePaneSell extends Component {
	constructor(props) {
		super(props)
		this.state = {
			isPaneOpen: false,
			isPaneOpenLeft: false,
			wallets: [],
			isModal: "",
			coinSelected: "",
			coinsValues: "9000",
		}

		this.closeSidePane = () => {
			this.setState({
				isPaneOpen: false,
			})
		}
	}

	handleChange(e) {
		console.log("handle change called", e.target.value)

		this.setState({
			coinsValues: 9000 * e.target.value,
		})
	}

	async componentDidMount() {
		const Wallet = await profileGet()
		this.setState({
			wallets: Wallet.data.wallets,
		})
	}

	render() {
		const {
			isOpen,
			closeSidePane,
			Coin,
			CoinValue,
			onChange,
			coinIcon,
		} = this.props
		return (
			<div>
				<SlidingPane
					className='some-custom-class'
					closeIcon={<div></div>}
					overlayClassName='some-custom-overlay-class'
					isOpen={isOpen}
					width='300px'
					onRequestClose={() => {
						// triggered on "<" on left top click or on outside click
						this.setState({ isPaneOpen: false })
					}}
				>
					<div className='sidePaneSell'>
						<div className='sidepaneHeader'>
							<div>Sell</div>
							<div onClick={closeSidePane}>
								<Icon
									type='close'
									style={{ fontSize: 20 }}
								></Icon>
							</div>
						</div>
						<div className='sidepaneTitle'>
							<div>
								<img
									style={{
										height: 30,
										width: 30,
										color: "#08c",
									}}
									alt=''
									src={coinIcon}
								></img>
								<span> {Coin}</span>
							</div>
							<div> ${CoinValue}</div>
						</div>
						<div className='portFolioBalance'>
							{" "}
							Portfolio Balance
						</div>
						<div className='portFolioAmount'> 7.0294444</div>
						<div className='convertorHeader'>Amount</div>
						<div className='convertorSection'>
							<input type='text' onChange={onChange}></input>

							<div>
								{" "}
								<Icon
									style={{ fontSize: "18px" }}
									type='swap'
								/>
							</div>
							<input type='text' value={CoinValue}></input>
						</div>

						<div className='paymentHeader'>Payment Method</div>
						<div className='paymentSection'>
							{this.state.wallets.map((item, index) => (
								<select>
									<option value={item.walletAdress}>
										{item.walletName}
									</option>
								</select>
							))}
						</div>

						<div>
							<Checkbox>
								I agree to{" "}
								<span style={{ color: "#e4b28d" }}>
									Terms and conditions
								</span>
							</Checkbox>{" "}
						</div>
						<div className='buttonSection'>
							<Button className='sellButton'>
								Sell ${CoinValue}
							</Button>
						</div>
					</div>
				</SlidingPane>
			</div>
		)
	}
}

export default SidePaneSell
