import React, { Component } from "react"
import { Switch, Route } from "react-router-dom"
import Dashboard from "../pages/Dashboard"
import Coins from "../pages/Coins"
import Transactions from "../pages/Transactions"
import ViewTransaction from "../pages/ViewTransactions"
import ViewCoin from "../pages/ViewCoin"
import News from "../pages/News"
// import TradeCoins from '../pages/TradeCoins';
import { ProtectedRoute } from "../protectedRoute/ProtectedRoutes"
import NotFound from "../error/NotFound"
import Portfolio from "../pages/Portfolio"
import BuyCoin from "../pages/BuyCoin"
import SellCoin from "../pages/SellCoin"
import ExchangeSell from "../pages/ExchangeSell"
import ExchangeBuy from "../pages/ExchangeBuy"
import WatchListTable from "../tables/WatchListTable"
import Security from "../pages/Security"

// This class allows the basic layouts to access these routes
class SubRoute extends Component {
	render() {
		const { match } = this.props
		return (
			<div>
				<Switch>
					<ProtectedRoute exact path={`/`} component={Dashboard} />
					<ProtectedRoute exact path={`/coins`} component={Coins} />
					<ProtectedRoute
						exact
						path={`/watchlist`}
						component={WatchListTable}
					/>
					<ProtectedRoute exact path={`/news`} component={News} />
					<ProtectedRoute
						exact
						path={`/portfolio`}
						component={Portfolio}
					/>
					<ProtectedRoute
						exact
						path={`/exchangesell`}
						component={ExchangeSell}
					/>
					<ProtectedRoute
						exact
						path={`/exchangebuy`}
						component={ExchangeBuy}
					/>
					<ProtectedRoute
						exact
						path={`/buycoin`}
						component={BuyCoin}
					/>
					<ProtectedRoute
						exact
						path={`/sellcoin`}
						component={SellCoin}
					/>
					<ProtectedRoute
						exact
						path={`/transactions`}
						component={Transactions}
					/>
					<ProtectedRoute
						exact
						path={`/viewtransactions`}
						component={ViewTransaction}
					/>
					<ProtectedRoute
						exact
						path={`/viewcoin`}
						component={ViewCoin}
					/>
					<ProtectedRoute
						exact
						path={`/security`}
						component={Security}
					/>
					<Route component={NotFound} />
				</Switch>
			</div>
		)
	}
}

export default SubRoute
