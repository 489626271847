import React, { Component } from "react"
import { Modal, Form, Select, Input, Button } from "antd"
import { connect } from "react-redux"
import { profileGet } from "../../services/Api"

const { Option } = Select

class CardModal extends Component {
	constructor(props) {
		super(props)
		this.state = {
			wallets: [],
		}

		this.handleSubmit = (e) => {
			e.preventDefault()
			this.props.form.validateFields((err, values) => {
				if (!err) {
					console.log("Received values of form: ", values)
				}
			})
		}

		this.handleSelectChange = (value) => {
			console.log(value)
			this.props.form.setFieldsValue({
				value,
			})
		}
	}

	async componentDidMount() {
		const Profile = await profileGet()
		this.setState({
			wallets: Profile.data.wallets,
		})
	}

	render() {
		const {
			visible,
			onCancel,
			onCreate,
			form,
			receiversWallet,
		} = this.props
		const { getFieldDecorator } = form
		return (
			<Modal
				className='login_block'
				visible={visible}
				title='Add new Card'
				okText='Recover'
				onCancel={onCancel}
				onOk={onCreate}
				footer={null}
			>
				<Form
					labelCol={{ span: 5 }}
					wrapperCol={{ span: 12 }}
					onSubmit={this.handleSubmit}
				>
					<label>Name of Card</label>
					<Form.Item>
						{getFieldDecorator("cardname", {
							initialValue: receiversWallet,
							rules: [
								{
									required: true,
									message: "Please input the Card Name",
								},
							],
						})(<Input placeholder='Card Name' />)}
					</Form.Item>
					<label>Card Number</label>
					<Form.Item>
						{getFieldDecorator("cardnumber", {
							rules: [
								{
									required: true,
									message: "Please input the Card Number",
								},
							],
						})(<Input placeholder='Card Number' />)}
					</Form.Item>
					<div className='coinWidget'>
						<div>
							<label>Valid Through</label>
							<Form.Item className='cardInputBox'>
								{getFieldDecorator("valid", {
									rules: [
										{
											required: true,
											message:
												"Please input the date valid",
										},
									],
								})(<Input placeholder='MM/YY' />)}
							</Form.Item>
						</div>
						<div>
							<label>CVV</label>
							<Form.Item className='cardInputBox'>
								{getFieldDecorator("cvv", {
									rules: [
										{
											required: true,
											message:
												"Please input the CVV of the  card",
										},
									],
								})(<Input placeholder='MM/YY' />)}
							</Form.Item>
						</div>
					</div>
					<Form.Item>
						<div className='coinButtonWidget'>
							<Button
								onClick={this.disabledButton}
								style={{ display: this.state.display }}
								className='coinButton profileApply'
								type='primary'
								htmlType='submit'
							>
								Apply
							</Button>
							<Button
								onClick={this.disabledButton}
								style={{ display: this.state.display }}
								className='coinButton profilecancel'
								type='primary'
								htmlType='submit'
							>
								<div className='profileText'> Cancel</div>
							</Button>
						</div>
					</Form.Item>
				</Form>
			</Modal>
		)
	}
}
const mapStateToProps = ({ currentUser }) => {
	return {
		allwallets: currentUser.data,
	}
}
export default connect(mapStateToProps)(Form.create()(CardModal))
