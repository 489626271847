import React, { Component } from "react"
import { Modal, Form, Select, Button, Row, Col, Popover } from "antd"
import { connect } from "react-redux"
import { sendTransaction } from "../../services/Ethtransactions"
import {
	changellyGetCurrencies,
	changellyGetMinimumAmount,
	changellyGetExchangeAmount,
	profileGet,
	changellycreateTransaction,
} from "../../services/Api"
import { trackPromise } from "react-promise-tracker"
import { toast } from "react-toastify"
import WalletModal from "./MakePayment"
const { Option } = Select

const content = (
	<div>
		<p>Select the first and second option</p>
		<p>
			Click on the Show MEB button to see the Minimum Exchangeable Balance
		</p>
		<p>
			Click on the Exchange Amount to show the Current Exchange amout for
			the two coins
		</p>
		<p>
			Select the wallet Address you want to use to trade with and then
			click on Start Trade
		</p>
	</div>
)

class TradeModal extends Component {
	constructor(props) {
		super(props)
		this.state = {
			tradedata: [],
			from: "",
			to: "",
			tradetransaction: "",
			modalVisible: false,
			modal2Visible: false,
			modal3Visible: false,
			modal4Visible: false,
			paymentModalVisible: false,
			MinimumExchangeBalance: "",
			loading: false,
			ExchangeAmount: "",
			wallets: [],
		}

		// start trade
		this.handleSubmit = (e) => {
			e.preventDefault()
			this.props.form.validateFields(async (err, values) => {
				if (!err) {
					console.log("Received values of form: ", values)
					const value = {
						...values,
					}
					const Transaction = await changellycreateTransaction(value)
					if (!Transaction.error) {
						this.setState({
							modal3Visible: true,
							tradetransaction:
								Transaction.data.result.payinAddress,
						})
					} else {
						toast(
							Transaction.message.error.message,
							"please try again"
						)
					}
				}
			})
		}

		//minimum balance promise
		this.setModalVisible = async () => {
			trackPromise(
				this.getMinimumBalance().then((result) => {
					this.setState({
						modalVisible: true,
						MinimumExchangeBalance: result,
					})
				})
			)
		}
		// display the transaction modal
		this.modalSendTransaction = () => {
			this.setState({
				modal4Visible: true,
			})
		}
		//get minimum balance
		this.getMinimumBalance = async () => {
			const values = {
				from: this.state.from,
				to: this.state.to,
			}
			let MinimumExchangeBalance = await changellyGetMinimumAmount(values)
			let results = MinimumExchangeBalance.result
			return results
		}

		//get Exchange Amount
		this.setModal2Visible = async () => {
			trackPromise(
				this.getExchangeAmount().then((result) => {
					console.log("Result", result)
					this.setState({
						modal2Visible: true,
						ExchangeAmount: result,
					})
				})
			)
		}

		//get Exchange amount promise
		this.getExchangeAmount = async () => {
			const values = {
				from: this.state.from,
				to: this.state.to,
			}
			let ExchangeAmount = await changellyGetExchangeAmount(values)
			let results = ExchangeAmount.result
			return results
		}

		//set the minimum balance modal off
		this.ModalMinimumBalanceInvisible = async () => {
			this.setState({ modalVisible: false })
		}
		//set the exchange amount modal off
		this.ModalExchangeAmountInvisible = async () => {
			this.setState({ modal2Visible: false })
		}

		// get the from value
		this.handleSelectFrom = (value) => {
			console.log(value)
			this.setState({
				from: value,
			})
			console.log(this.state.from)
		}

		//get  the exchange to value
		this.handleSelectTo = (value) => {
			console.log(value)
			this.setState({
				to: value,
			})
			console.log(this.state.to)
		}

		this.setTransactionModalInvisible = () => {
			this.setState({
				modal3Visible: false,
			})
		}

		this.saveFormRef = (formRef) => (this.formRef = formRef)
		this.handleCancel = (e) => this.setState({ modal4Visible: false })
		//make the actual payment
		this.ConductPayment = () => {
			const form = this.formRef.props.form
			form.validateFields(async (err, values) => {
				let ReceiverWalletaddress = values.address
				let SenderWalletAddress = values.wallet
				let Amount = values.amount
				console.log(values)
				console.log("SenderWalletAddress", SenderWalletAddress)
				sendTransaction(
					ReceiverWalletaddress,
					SenderWalletAddress,
					Amount
				)
			})
		}
	}

	async componentDidMount() {
		const Profile = await profileGet()
		trackPromise(
			this.getTradeData().then((result) => {
				console.log("Result", result)
				this.setState({
					tradedata: result,
					wallets: Profile.data.wallets,
				})
			})
		)
	}

	//get All coins data
	async getTradeData() {
		const dataObject = []
		const data = await changellyGetCurrencies()
		var dataArray = data.result
		dataArray.map((item, i) => {
			dataObject.push({
				name: item.name,
				fullname: item.fullName,
			})
		})
		return dataObject
	}

	render() {
		const { visible, onCancel, onCreate, form } = this.props
		const { getFieldDecorator } = form
		return (
			<div>
				<Modal
					className='login_block'
					visible={visible}
					title='Conduct an Exchange'
					okText='Recover'
					onCancel={onCancel}
					onOk={onCreate}
					footer={null}
					width={800}
				>
					<Form
						labelCol={{ span: 5 }}
						wrapperCol={{ span: 12 }}
						onSubmit={this.handleSubmit}
					>
						<Form.Item label='Coin to Exchange from '>
							{getFieldDecorator("from", {
								rules: [
									{
										required: true,
										message:
											"Please select the coin you want to exchange",
									},
								],
							})(
								<Select
									placeholder='Please select the coin you want to exchange'
									onChange={this.handleSelectFrom}
								>
									{this.state.tradedata.map((item, key) => (
										<Option value={item.name}>
											{item.fullname}
										</Option>
									))}
								</Select>
							)}
						</Form.Item>

						<Form.Item label='Coin to Exchange to '>
							{getFieldDecorator("to", {
								rules: [
									{
										required: true,
										message:
											"Please select the coin you want to exchange with!",
									},
								],
							})(
								<Select
									placeholder='Please select the coin you want to exchange with'
									onChange={this.handleSelectTo}
								>
									{this.state.tradedata.map((item, key) => (
										<Option value={item.name}>
											{item.fullname}
										</Option>
									))}
								</Select>
							)}
						</Form.Item>

						<Form.Item label='My Wallets '>
							{getFieldDecorator("wallet", {
								rules: [
									{
										required: true,
										message:
											"Please select the wallet you want to receive coins!",
									},
								],
							})(
								<Select
									placeholder='Please select the wallet address you want to use above'
									onChange={this.handleSelectChange}
								>
									{this.state.wallets.map((item, key) => (
										<Option value={item.walletAdress}>
											{item.walletName}
										</Option>
									))}
								</Select>
							)}
						</Form.Item>
						<Row gutter={16}>
							<Col md={6} lg={6}>
								<p>Instructions</p>
								<Popover
									content={content}
									title='Trade Instructions'
								>
									<Button
										style={{ span: 12, offset: 5 }}
										type='primary'
									>
										Instructions
									</Button>
								</Popover>
							</Col>
							<Col md={6} lg={6}>
								<p>Step1</p>
								<Button
									style={{ span: 12, offset: 5 }}
									onClick={() => this.setModalVisible(true)}
									type='primary'
								>
									Show MIB
								</Button>
							</Col>
							<Col md={6} lg={6}>
								<p>Step2</p>
								<Button
									style={{
										span: 12,
										offset: 5,
										paddingLeft: 10,
									}}
									onClick={() => this.setModal2Visible(true)}
									type='primary'
								>
									Exchange Amount
								</Button>
							</Col>
							<Col md={6} lg={6}>
								<p>Step3</p>
								<Form.Item wrapperCol={{ span: 12 }}>
									<Button
										onClick={onCreate}
										type='primary'
										htmlType='submit'
									>
										Start Trade
									</Button>
								</Form.Item>
							</Col>
						</Row>
					</Form>
				</Modal>

				<Modal
					title='Minimum Exchangable Balance'
					style={{ top: 20 }}
					visible={this.state.modalVisible}
					onOk={() => this.ModalMinimumBalanceInvisible(false)}
					onCancel={() => this.ModalMinimumBalanceInvisible(false)}
				>
					<p>Please make sure you have selected two coins</p>
					<p>
						The minimum Exchangable Balance for {this.state.from}{" "}
						and {this.state.to} is
					</p>
					<p>{this.state.MinimumExchangeBalance}</p>
				</Modal>

				<Modal
					title='Exchangable Amount'
					style={{ top: 20 }}
					visible={this.state.modal2Visible}
					onOk={() => this.ModalExchangeAmountInvisible(false)}
					onCancel={() => this.ModalExchangeAmountInvisible(false)}
				>
					<p>Please make sure you have selected two coins</p>
					<p>
						The current Exchange Amount for {this.state.from} and{" "}
						{this.state.to} is
					</p>
					<p>{this.state.ExchangeAmount}</p>
				</Modal>

				<Modal
					title='Transaction Data'
					style={{ top: 20 }}
					width={700}
					visible={this.state.modal3Visible}
					onOk={() => this.setTransactionModalInvisible(false)}
					onCancel={() => this.setTransactionModalInvisible(false)}
				>
					<p>
						Congratulations for you for starting this trade.Please
						follow the following instructions.{" "}
					</p>
					<ol>
						Please click on Send Transaction Button and input the
						number of coins you want to exchange
					</ol>
					<ol>
						Send the Coins you want to exchange to the wallet
						address provided after you click the button{" "}
					</ol>
					<ol>
						Please make sure to select the wallet address you want
						to use
					</ol>

					<Button
						style={{ span: 12, offset: 5, paddingLeft: 10 }}
						onClick={() => this.modalSendTransaction(true)}
						type='primary'
					>
						{" "}
						Send Transaction
					</Button>
				</Modal>

				<WalletModal
					wrappedComponentRef={this.saveFormRef}
					visible={this.state.modal4Visible}
					receiversWallet={this.state.tradetransaction}
					onCancel={this.handleCancel}
					onCreate={this.ConductPayment}
				/>
			</div>
		)
	}
}
const mapStateToProps = ({ currentUser }) => {
	return {
		allwallets: currentUser.data,
	}
}
export default connect(mapStateToProps)(Form.create()(TradeModal))
