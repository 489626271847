import React, { Component } from "react"
import { Card, Row, Col, Icon } from "antd"
import { getWatchlistCoins } from "../../services/Cryptoapis"
import { Sparklines, SparklinesLine, SparklinesSpots } from "react-sparklines"
import { withRouter } from "react-router-dom"

class WatchList extends Component {
	constructor(props) {
		super(props)
		this.state = {
			coin: [],
		}

		this.viewCoin = (text) => {
			console.log(text)
			this.props.history.push({
				pathname: "/viewcoin",
				coin: text,
			})
		}
	}

	async WatchListCoins() {
		const watchListCoins = await getWatchlistCoins()

		this.setState({
			coin: watchListCoins,
		})
	}

	async componentDidMount() {
		this.WatchListCoins()
		this.intervalID = setInterval(this.WatchListCoins.bind(this), 5000)
	}
	componentWillUnmount() {
		clearInterval(this.intervalID)
	}

	render() {
		return (
			<div>
				{this.state.coin.map((coin, index) => (
					<Card
						onClick={() => this.viewCoin(coin.name)}
						style={{ width: 210 }}
						bordered={false}
					>
						<div className='coinHeader'>
							<div>
								<h6
									onClick={() => this.viewCoin(coin.name)}
									style={{ color: "#2d1582" }}
								>
									{" "}
									{coin.name}
								</h6>
							</div>
							<div>
								<img
									style={{ height: 30, width: 30 }}
									alt=''
									src={coin.icon}
								></img>
							</div>
						</div>
						<Sparklines
							data={[2, 4, 3, 5, 7, 8, 4, 9, 8]}
							width={100}
							height={20}
							margin={5}
						>
							<SparklinesLine
								style={{
									fill: "none",
									strokeWidth: "1",
									stroke: "#2196f3",
								}}
							/>
							<SparklinesSpots />
						</Sparklines>
						<div
							onClick={() => this.viewCoin(coin.name)}
							className='coinHeader'
						>
							<div>
								<h6 style={{ color: "green" }}>
									{coin.lastprice}
								</h6>
							</div>

							<div>
								<p
									style={{
										color:
											coin.hourchange > 1
												? "green"
												: "red",
									}}
								>
									($
									{coin.hourchange.toLocaleString(
										navigator.language,
										{ minimumFractionDigits: 2 }
									)}
									)
								</p>
							</div>
						</div>
					</Card>
				))}
			</div>
		)
	}
}

export default withRouter(WatchList)
