import React, { Component } from "react"
import { Button } from "antd"
import { withRouter } from "react-router-dom"
import {
	walletLink,
	walletUpdate,
	AddWalletToWatch,
	profileGet,
} from "../services/Api"
import { walletcheck } from "../services/Wallets"
import { Link } from "react-router-dom"

import { toast } from "react-toastify"
toast.configure()
class WalletsAdd extends Component {
	constructor(props) {
		super(props)
		this.state = {
			wallets: [],
		}
		this.submit = this.submit.bind(this)

		//making sure the wallet is synched to the backend
		this.walletAdd = async () => {
			const walletDetails = await walletcheck()
			console.log(walletDetails)
			if (walletDetails.walletinfo !== "false") {
				console.log(walletDetails.walletinfo.walletBalance)

				// update users profile with the wallet details
				const Wallet = {
					wallets: {
						coinName: "ETH",
						coinImage: "",
						walletName: walletDetails.walletinfo.walletName,
						walletAdress: walletDetails.walletinfo.walletAddress,
						balance:
							walletDetails.walletinfo.walletBalance /
							1000000000000000000,
					},
				}
				const WalletInfo = await walletLink(Wallet)

				//dispatch user info in order to update wallet status
				// this.props.dispatch(handleUserSetup(WalletInfo));

				//updating blocknative Add to Watch API

				const WalletAddressToWatch = {
					apiKey: process.env.REACT_APP_WALLET_API,
					address: walletDetails.walletinfo.walletAddress,
					blockchain: "ethereum",
					networks: ["main", "rinkeby"],
				}

				const AddWalletAddressToWatch = await AddWalletToWatch(
					WalletAddressToWatch
				)
				if (AddWalletAddressToWatch.msg === "success") {
					toast(
						"Your Etherium Wallet has been authorized your wallet address Added",
						{ type: "success" }
					)
				} else {
					toast(
						"There was a problem with the full configuration, please try again",
						{ type: "error" }
					)
				}
			} else {
				toast("You did not select any wallet", { type: "error" })
			}
		}

		this.walletupdate = async () => {
			const walletDetails = await walletcheck()
			console.log(walletDetails)
			if (walletDetails.walletinfo !== "false") {
				console.log(walletDetails.walletinfo.walletBalance)

				// update users profile with the wallet details
				const Wallet = {
					wallets: {
						coinName: "ETH",
						coinImage: "",
						walletName: walletDetails.walletinfo.walletName,
						walletAdress: walletDetails.walletinfo.walletAddress,
						balance:
							walletDetails.walletinfo.walletBalance /
							1000000000000000000,
					},
				}
				const WalletInfo = await walletUpdate(Wallet)

				//dispatch user info in order to update wallet status
				// this.props.dispatch(handleUserSetup(WalletInfo));

				//updating blocknative Add to Watch API

				const WalletAddressToWatch = {
					apiKey: process.env.REACT_APP_WALLET_API,
					address: walletDetails.walletinfo.walletAddress,
					blockchain: "ethereum",
					networks: ["main", "rinkeby"],
				}

				const AddWalletAddressToWatch = await AddWalletToWatch(
					WalletAddressToWatch
				)
				if (AddWalletAddressToWatch.msg === "success") {
					toast(
						"Your Etherium Wallet has been authorized your wallet address Added",
						{ type: "success" }
					)
				} else {
					toast(
						"There was a problem with the full configuration, please try again",
						{ type: "error" }
					)
				}
			} else {
				toast("You did not select any wallet", { type: "error" })
			}
		}

		this.viewWallet = (balance, type) => {
			console.log("BALANCE", balance)
			this.props.history.push({
				pathname: "/walletdetails",
				coin: { walletBalance: balance, wallettype: type },
			})
		}
	}

	async submit(ev) {
		let { token } = await this.props.stripe.createToken({ name: "Name" })
		console.log(token)
	}

	async componentDidMount() {
		const Profile = await profileGet()
		this.setState({
			wallets: Profile.data.wallets,
		})
	}

	render() {
		return (
			<React.Fragment>
				<div className='profileWallet'>
					<Link to='/profile'>Wallets </Link>
				</div>
				<div className='walletContent'>
					<div className='walletLinks'>
						{this.state.wallets.map((item, index) => (
							<div
								onClick={() =>
									this.viewWallet(item.balance, item.name)
								}
								className='profilewalletTitle'
							>
								<img
									style={{ height: 30, width: 30 }}
									src={require("../images/metamask.png")}
								></img>

								<div className='profilewalletName'>
									{item.walletName}
								</div>
								<div className='profilewalletStatus'>
									Linked
								</div>
							</div>
						))}
					</div>
					<div className='walletAddandLink'>
						<div
							className='profileAddButton'
							onClick={this.walletAdd}
						>
							+ Link Wallet{" "}
						</div>
						<div
							className='profileAddButton'
							onClick={this.walletupdate}
						>
							+ Update Wallet{" "}
						</div>
					</div>
				</div>
			</React.Fragment>
		)
	}
}

export default withRouter(WalletsAdd)
