import { showLoading, hideLoading } from "react-redux-loading";


const SET_USER = 'SET_USER';

const setUser = user => {
    return {
        type: SET_USER,
        user
    }
}

const handleUserSetup = (user) => {
    return dispatch => {
        dispatch(showLoading());
        dispatch(setUser(user));
        dispatch(hideLoading());
    }
}

export { SET_USER, handleUserSetup };
