import React, { Component } from "react"
import "./Widget.scss"

class WidgetTechnicalAnalysis extends Component {
	constructor() {
		super()
	}

	componentDidMount() {
		if (document.getElementById("technicalanalysis")) {
			const script = document.createElement("script")
			script.src =
				"https://s3.tradingview.com/external-embedding/embed-widget-technical-analysis.js"
			script.async = true
			script.innerHTML = JSON.stringify({
				interval: "1m",
				width: "100%",
				isTransparent: false,
				height: 450,
				symbol: `${this.props.coin}USD`,
				showIntervalTabs: true,
				locale: "en",
				colorTheme: "light",
			})

			document.getElementById("technicalanalysis").appendChild(script)
		}
	}

	render() {
		return <div id='technicalanalysis'> </div>
	}
}

export default WidgetTechnicalAnalysis
