import React, { Component } from "react"
import { Pie, Doughnut } from "react-chartjs-2"
import {} from "chartjs-plugin-doughnutlabel"

class PieChart extends Component {
	constructor(props) {
		super(props)
		this.state = {
			labels: ["1", "2", "3"],
			datasets: [
				{
					data: [2000, 4000, 2850],
					backgroundColor: ["#4f5d6e", "#00bfa5", "#ffe700"],
				},
			],
			text: "45",
		}
	}
	render() {
		return (
			<div className='pieChartGraph'>
				<Doughnut
					data={{
						// labels:this.state.labels,
						datasets: this.state.datasets,
						text: "45",
					}}
					options={{
						cutoutPercentage: 80,
						tooltips: {
							callbacks: {
								title: function(tooltipItem, data) {
									return data["labels"][tooltipItem["index"]]
								},
								label: function(tooltipItem, data) {
									return data["datasets"][0]["data"][
										tooltipItem["index"]
									]
								},
							},
							backgroundColor: "#FFF",
							titleFontSize: 16,
							titleFontColor: "#0066ff",
							bodyFontColor: "#000",
							bodyFontSize: 14,
							displayColors: false,
						},
					}}
					height='100px'
				></Doughnut>
			</div>
		)
	}
}

export default PieChart
