import React, { Component } from "react"
import { Card, Row, Col, Icon } from "antd"
import {
	TotalTransactions,
	AmountTransacted,
} from "../../calculations/TransactionCalculation"
import { profileGet, walletPut, AddWalletToWatch } from "../../services/Api"
import { handleUserSetup } from "../../redux/actions/User"
import { toast } from "react-toastify"
import WatchList from "../tables/WatchList"
import WalletsAdd from "../../payments/WalletsAdd"
import { Link } from "react-router-dom"
import DashboardNews from "../tables/DashBoardNews"
import { Container } from "react-bootstrap"
import UserSellingList from "../tables/SellingListDashBoard"
import PieChart from "../../charts/PieChart"
import ReferFriend from "../Modals/ReferFriend"

class DashBoard extends Component {
	constructor(props) {
		super(props)
		this.state = {
			short: "",
			mounted: false,
			username: "",
			visible: false,
			transactions: "",
			amounttransacted: "",
		}

		this.showModal = () => {
			this.setState({ visible: true })
		}
		this.saveFormRef = (formRef) => (this.formRef = formRef)
		this.handleCancel = (e) =>
			this.setState({ visible: false, recovermail: "", error: false })

		this.handleCreate = () => {
			const form = this.formRef.props.form
			form.validateFields(async (err, values) => {
				const resetPasswordValue = {
					...values,
				}
			})
		}
	}

	async componentDidMount() {
		const Profile = await profileGet()
		if (Profile.message === "Failed to authenticate token") {
			this.props.history.push("/signin")
		}
		const Transactions = await TotalTransactions()
		const amountTransacted = await AmountTransacted()
		this.setState({
			transactions: Transactions,
			amounttransacted: amountTransacted,
		})
	}

	componentWillUnmount() {
		clearInterval(this.intervalID)
	}

	render() {
		return (
			<React.Fragment>
				<Container>
					<Col sm={24} lg={18} md={16}>
						<div className='dashBoardBody'>
							<Row>
								<div className='piechart'>
									<div className='transactionsHeader'>
										<div className='transactionsTitle'>
											{" "}
											<Link to='/portfolio'>
												My Portfolio
											</Link>
										</div>
										<div className='transactionNumbers'>
											{" "}
										</div>
									</div>
									<Row>
										<Col sm={24} md={12}>
											<PieChart />
										</Col>
										<UserSellingList></UserSellingList>
									</Row>
								</div>
							</Row>
							<Row>
								<Col sm={24} lg={16} md={16}>
									<div className='transactionBody'>
										<div className='transactionsHeader'>
											<div className='transactionsTitle'>
												<Link to='/transactions'>
													Transactions
												</Link>
											</div>
											<div className='transactionNumbers'>
												<div> Completed </div>
												<div className='blue'>
													{" "}
													{this.state.transactions}
												</div>
												<div> Pending</div>
												<div className='blue'> 0</div>
											</div>
										</div>
										<div className='transactionDetails'>
											<Link to='/transactions'>
												{" "}
												Transaction details
											</Link>
										</div>
									</div>
								</Col>

								<Col sm={24} lg={8} md={8}>
									<div className='walletsBody'>
										<WalletsAdd />
									</div>
								</Col>
							</Row>
							<Row>
								<Col sm={24} lg={16} md={16}>
									{" "}
									<div className='transactionBody'>
										<div className='transactionsHeader'>
											<div className='transactionsTitle'>
												{" "}
												<Link to='/news'> News</Link>
											</div>
										</div>
										<div className='newsBody'>
											<DashboardNews />
										</div>
									</div>
								</Col>
								<Col sm={24} lg={8} md={8}>
									{" "}
									<div className='walletsBody'>
										<div
											onClick={this.showModal}
											className='referall'
										>
											<img
												style={{
													height: 43.7,
													width: 52,
												}}
												src={require("../../icons/friends.svg")}
											></img>
										</div>
										<div
											onClick={this.showModal}
											className='referallText'
										>
											Refer a friend
										</div>
										<div className='referallDescription'>
											Click to enter your friend's email
											address and when they sign up, you
											both get an exciting reward!
										</div>
									</div>
								</Col>
							</Row>
						</div>
					</Col>
					<Col sm={24} lg={6} md={8}>
						<div className='watchListBody' style={{ padding: 20 }}>
							<Link to='/watchlist'> WatchList</Link>

							<WatchList />
							<div className='watchListAddMore'>
								{" "}
								<Link to='/coins'> + Add More</Link>{" "}
							</div>
						</div>
					</Col>
				</Container>

				<ReferFriend
					wrappedComponentRef={this.saveFormRef}
					visible={this.state.visible}
					onCancel={this.handleCancel}
					onCreate={this.handleCreate}
				/>
			</React.Fragment>
		)
	}
}

export default DashBoard
