import { transactionGet, GetTradeData, getNotification } from "../services/Api"

export async function TotalTransactions() {
	const Transactions = await transactionGet()
	const TradeData = await GetTradeData()
	const totaltransactions = Transactions.data.length + TradeData.data.length

	return totaltransactions
}

export async function TotalUnreadNotifactions() {
	const Notifications = await getNotification()
	let count = 0
	for (var i = 0; i < Notifications.data.length; i++) {
		if (Notifications.data[i].isRead === false) {
			count++
		}
	}

	console.log("COUNT", count)
	return count
}

export async function AmountTransacted() {
	let totalTransacted = 0
	const Transactions = await transactionGet()

	Transactions.data.map((item, i) => {
		console.log(item.value)
		totalTransacted += parseInt(item.value)
	})

	return totalTransacted
}
