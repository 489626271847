import React, { Component } from "react"
import { Row, Col, Card, Icon, Form, Select } from "antd"
import { Link } from "react-router-dom"
import CoinBuyTable from "../tables/CoinBuyTable"
import { CoinsOnTrade } from "../../services/Cryptoapis"
import { searchSeller } from "../../services/Api"
import { Button } from "react-bootstrap"
import { toast } from "react-toastify"
const { Option } = Select

class ExchangeBuy extends Component {
	constructor(props) {
		super(props)
		this.state = {
			coinvalue: "",
			visible: false,
			selectedcoins: [],
			sellerscoin: [],
			currentcoin: "",
			coinQuantity: "",
			coinName: "",
			coinsValues: "",
			value: "",
		}

		this.handleSubmit = (e) => {
			e.preventDefault()
			this.props.form.validateFields((err, values) => {
				if (!err) {
					console.log("Received values of form: ", values)
				}
			})
		}

		this.handleSelectChange = (value) => {
			const coininfo = JSON.parse(value)
			this.setState({
				currentcoin: coininfo.price,
				coinsValues: coininfo.price,
				coinName: coininfo.name,
			})
		}

		this.handleConverterChange = (e) => {
			if (e.target.value != "") {
				const value = parseInt(this.state.coinsValues) * e.target.value
				this.setState({
					currentcoin: value,
					coinQuantity: e.target.value,
				})
			}
		}

		this.viewListings = async (e) => {
			const listingdata = {
				coinName: this.state.coinName,
				coinQty: this.state.coinQuantity,
				amount: this.state.currentcoin,
			}
			const Seller = await searchSeller(listingdata)
			console.log("Seller", Seller)
			if (Seller.message === "Success") {
				this.setState({
					sellerscoin: Seller.data,
				})
			} else {
				toast(Seller.message)
			}
		}
	}

	async componentDidMount() {
		let coinToSelect = await CoinsOnTrade()
		console.log(coinToSelect)
		this.setState({
			selectedcoins: coinToSelect,
		})

		console.log(this.state.selectedcoins)
	}

	render() {
		const props = this.props
		const { value } = this.state
		const { getFieldDecorator } = props.form
		return (
			<React.Fragment>
				<div className='buysellHeader'>
					<span>Buy</span>
					<div className='buysellButton'>
						<Link to='/exchangebuy'>
							{" "}
							<Button className='buyButtontab activeButton'>
								Buy
							</Button>
						</Link>
						<Link to='/exchangesell'>
							<Button className='sellButtontab'>Sell</Button>
						</Link>
					</div>
				</div>
				<Col sm={24} md={10}>
					<Row>
						<div className='totalHoldings buyButtons'>
							<div className='selectCoin'>
								<div className='buyContentText'>
									<div className='paymentHeader'>
										Select Coin
									</div>
								</div>
								<Form
									labelCol={{ span: 5 }}
									wrapperCol={{ span: 12 }}
									onSubmit={this.handleSubmit}
								>
									<Form.Item>
										{getFieldDecorator("wallet", {
											rules: [
												{
													required: true,
													message:
														"Please select  coins!",
												},
											],
										})(
											<Select
												onChange={
													this.handleSelectChange
												}
											>
												{this.state.selectedcoins.map(
													(item, key) => (
														<Option
															value={JSON.stringify(
																{
																	name:
																		item.name,
																	price:
																		item.lastprice,
																}
															)}
														>
															<img
																style={{
																	height: 30,
																	width: 30,
																	margin: 7,
																}}
																src={item.icon}
															></img>
															{item.name}
														</Option>
													)
												)}
											</Select>
										)}
									</Form.Item>
								</Form>
							</div>
							<div className='convertorHeader'>Enter Amount</div>
							<div className='convertorSection'>
								<input
									type='number'
									onChange={(text) =>
										this.handleConverterChange(text)
									}
								></input>
								<div>
									{" "}
									<Icon
										style={{ fontSize: "18px" }}
										type='swap'
									/>
								</div>
								<input
									type='text'
									value={"$" + this.state.currentcoin}
								></input>
							</div>
							<div className='buybuttonSection'>
								<Button
									onClick={this.viewListings}
									className='payButton'
								>
									View Listing
								</Button>
							</div>
						</div>
					</Row>
				</Col>
				<Col sm={24} md={14}>
					<div className='coinTable'>
						<div className='totalHoldingsTitle'>
							Global Selling List
						</div>
						<div className='transactionTable'>
							<CoinBuyTable data={this.state.sellerscoin} />
						</div>
					</div>
				</Col>
			</React.Fragment>
		)
	}
}

export default Form.create()(ExchangeBuy)
