import { showLoading, hideLoading } from "react-redux-loading";


const SET_WALLET_INFO = 'SET_WALLET_INFO';

const setWallet = wallet => {
    return {
        type: SET_WALLET_INFO,
        wallet
    }
}

const handleWalletInformation = (wallet) => {
    return dispatch => {
        dispatch(showLoading());
        dispatch(setWallet(wallet));
        dispatch(hideLoading());
    }
}

export { SET_WALLET_INFO, handleWalletInformation };