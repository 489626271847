import React, { Component } from "react"
import { getNotification } from "../../services/Api"
import AppHeader from "../appHeader/AppHeader"
import {
	MongoTimeConvert,
	convertLocalDateToUTCDate,
} from "../../converters/timeConverters"

class Notifications extends Component {
	constructor(props) {
		super(props)
		this.state = {
			notifications: [],
		}

		this.conductSale = (params) => {
			console.log(params)
			if (params != undefined) {
				this.props.history.push({
					pathname: "/sellcoin",
					coin: {
						sellerListId: params.sellerListId,
						sellerId: params.sellerId,
						buyerId: params.buyerId,
						buyerName: params.buyerName,
						coinName: params.coinName,
						coinQty: params.coinQty,
						amount: params.amount,
						walletName: params.walletName,
						walletAdress: params.walletAdress,
					},
				})
			}
		}
	}

	async componentDidMount() {
		const Notifications = await getNotification()
		console.log("Notifications", Notifications.data)
		this.setState({
			notifications: Notifications.data,
		})
	}

	render() {
		return (
			<React.Fragment>
				<header className='headerdiv'>
					<AppHeader />
				</header>

				<div className='notificationPage'>
					<div className='PorfolioHeader'>My Notications</div>
					<div className='notificationBody'>
						{this.state.notifications.map((item) => (
							<div
								onClick={() =>
									this.conductSale(item.otherParams)
								}
							>
								<div className='notificationDay'>
									{item.type.toUpperCase()}
								</div>
								<div className='notificationText'>
									<span>
										{" "}
										<div
											dangerouslySetInnerHTML={{
												__html: item.message,
											}}
										/>
									</span>
									<span>
										{MongoTimeConvert(item.createdAt)}
									</span>
								</div>
							</div>
						))}
					</div>
				</div>
			</React.Fragment>
		)
	}
}

export default Notifications
