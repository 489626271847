import React, { Component } from "react"
import { getUsersListedCoins } from "../../services/Api"
import { Row, Col, Card, Icon, Form, Select } from "antd"
class SellListDashBoard extends Component {
	constructor(props) {
		super(props)

		this.state = {
			sellinglist: [],
		}
	}

	async getListedCoins() {
		let UsersListedCoins = await getUsersListedCoins()
		console.log("ListedCoins", UsersListedCoins)
		if (UsersListedCoins.message === "Success") {
			this.setState({
				sellinglist: UsersListedCoins.data,
			})
		} else {
		
		}
	}
	async componentDidMount() {
		this.getListedCoins()


	}

	render() {
		return (
			<React.Fragment>
				<Col sm={24} md={12}>
					{this.state.sellinglist.map((item) => (
						<Row>
							<div className='chartDetails'>
								<div className='coinNames'>
									<div className='singleCoin'>
										{item.coinName}
									</div>
								</div>
								<div className='coinInvestment'>
									<div>
										{" "}
										$
										{item.amount.toLocaleString(
											navigator.language,
											{ minimumFractionDigits: 2 }
										)}
									</div>
								</div>
								<div className='coinAmount'>
									<div>
										{" "}
										{item.coinQty} {item.coinName}
									</div>
								</div>
							</div>
						</Row>
					))}
				</Col>
			</React.Fragment>
		)
	}
}

export default SellListDashBoard
