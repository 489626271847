import React, { Component } from "react"
import { Modal, Form, Select, Input, Button, Icon } from "antd"
import { connect } from "react-redux"
import { profileGet } from "../../services/Api"
import { CoinsOnTrade } from "../../services/Cryptoapis"

const { Option } = Select

class WalletModal extends Component {
	constructor(props) {
		super(props)
		this.state = {
			wallets: [],
			currentcoin: "",
			coinsValues: "",
			coinName: "",
			selectedcoins: [],
		}

		this.handleSubmit = (e) => {
			e.preventDefault()
			this.props.form.validateFields((err, values) => {
				if (!err) {
					console.log("Received values of form: ", values)
				}
			})
		}

		this.handleSelectChange = (value) => {
			console.log("VALUE", value)
			const coininfo = JSON.parse(value)
			this.setState({
				currentcoin: coininfo.price,
				coinsValues: coininfo.price,
				coinName: coininfo.name,
			})
		}

		this.handleConverterChange = (e) => {
			if (e.target.value != "") {
				const value = parseInt(this.state.coinsValues) * e.target.value
				this.setState({
					currentcoin: value,
					coinQuantity: e.target.value,
				})
			}
		}
	}

	async componentDidMount() {
		let coinToSelect = await CoinsOnTrade()
		const Profile = await profileGet()
		this.setState({
			wallets: Profile.data.wallets,
			selectedcoins: coinToSelect,
		})
		console.log(this.state.selectedcoins)
	}

	render() {
		const {
			visible,
			onCancel,
			onCreate,
			form,
			receiversWallet,
		} = this.props
		const { getFieldDecorator } = form
		return (
			<Modal
				className='login_block makePayment'
				visible={visible}
				title='Send'
				okText='Recover'
				onCancel={onCancel}
				onOk={onCreate}
				footer={null}
			>
				<Form
					labelCol={{ span: 5 }}
					wrapperCol={{ span: 12 }}
					onSubmit={this.handleSubmit}
				>
					<div className='coinWalletSelect'>
						<div className='coinSelect'>
							<Form.Item label='Currency '>
								{getFieldDecorator("currency", {
									rules: [
										{
											required: true,
											message: "Please select a coin!",
										},
									],
								})(
									<Select
										placeholder='Please select the currency'
										onChange={this.handleSelectChange}
									>
										{this.state.selectedcoins.map(
											(item, key) => (
												<Option
													value={JSON.stringify({
														name: item.name,
														price: item.lastprice,
													})}
												>
													{" "}
													<img
														style={{
															height: 30,
															width: 30,
															margin: 10,
														}}
														src={item.icon}
													></img>
													{item.name}
												</Option>
											)
										)}
									</Select>
								)}
							</Form.Item>
						</div>
						<div className='walletSelect'>
							<Form.Item label='From '>
								{getFieldDecorator("wallet", {
									rules: [
										{
											required: true,
											message:
												"Please select your wallet!",
										},
									],
								})(
									<Select placeholder='Please select the wallet address'>
										{this.state.wallets.map((item, key) => (
											<Option value={item.walletAdress}>
												{item.walletName}
											</Option>
										))}
									</Select>
								)}
							</Form.Item>
						</div>
					</div>

					<Form.Item label='To '>
						{getFieldDecorator("address", {
							initialValue: receiversWallet,
							rules: [
								{
									required: true,
									message:
										"Please input the wallet address of the receiver",
								},
							],
						})(<Input placeholder='walletAdress' />)}
					</Form.Item>

		
					<div className='convertorSection'>
						<Form.Item label='Coins '>
							{getFieldDecorator("initialvalue", {
								rules: [
									{
										required: true,
										message:
											"Please input the amount of eth you want to send  to the receiver",
									},
								],
							})(
								<Input
									onChange={(text) =>
										this.handleConverterChange(text)
									}
									placeholder='What is this transaction for(optional)'
									type='textarea'
								/>
							)}
						</Form.Item>

						<div>
							{" "}
							<Icon style={{ fontSize: "18px" }} type='swap' />
						</div>

						<Form.Item label='Value '>
							{getFieldDecorator(
								"convertedvalue",
								{
									initialValue: "$" + this.state.currentcoin,
								},
								{
									rules: [
										{
											required: true,
											message:
												"Please input the amount of eth you want to send  to the receiver",
										},
									],
								}
							)(
								<Input
									placeholder='What is this transaction for(optional)'
									type='textarea'
								/>
							)}
						</Form.Item>
					</div>

					<Form.Item label='Description '>
						{getFieldDecorator("description", {
							rules: [
								{
									required: true,
									message:
										"Please input the amount of eth you want to send  to the receiver",
								},
							],
						})(
							<Input
								placeholder='What is this transaction for(optional)'
								type='textarea'
							/>
						)}
					</Form.Item>

					<Form.Item wrapperCol={{ span: 12 }}>
						<Button
							onClick={onCreate}
							type='primary'
							htmlType='submit'
						>
							Send
						</Button>
					</Form.Item>
				</Form>
			</Modal>
		)
	}
}
const mapStateToProps = ({ currentUser }) => {
	return {
		allwallets: currentUser.data,
	}
}
export default connect(mapStateToProps)(Form.create()(WalletModal))
