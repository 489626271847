import React, { Component } from "react"
import TradingViewWidget, { Themes } from "react-tradingview-widget"

class WidgetByCoin extends Component {
	render() {
		return (
			<React.Fragment>
				<TradingViewWidget symbol={`${this.props.coin}USD`} />
			</React.Fragment>
		)
	}
}

export default WidgetByCoin
