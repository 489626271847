import React, { Component } from "react"
import { Button } from "antd"
import { Table } from "react-bootstrap"

import { withRouter } from "react-router-dom"

class UserSellingList extends Component {
	constructor(props) {
		super(props)
		this.state = { cryptodata: [] }

		this.buyCoin = (coinName, seller, quantity, amount) => {
			this.props.history.push({
				pathname: "/buycoin",
				coin: {
					name: coinName,
					seller: seller,
					quantity: quantity,
					amount: amount,
				},
			})
		}
	}
	render() {
		return (
			<div>
				<Table responsive='lg' borderless>
					<thead className='tableHead'>
						<tr>
							<th className='tabbleWidthIcon'>#</th>
							<th className='coinNumberWidth'>Coin</th>
							<th className='tableWidth'>Value</th>
							<th className='tableWidth'>Amount</th>
							<th className='coinNumberWidth'>Status </th>
						</tr>
					</thead>

					{this.props.data.map((coin, index) => (
						<tbody>
							<tr>
								<td>{index}</td>
								<td>{coin.coinName}</td>
								<td>
									{coin.coinQty} {coin.coinName}{" "}
								</td>
								<td>
									{" "}
									$
									{coin.amount.toLocaleString(
										navigator.language,
										{ minimumFractionDigits: 2 }
									)}
								</td>
								<td style={{ color: "green" }}>
									{coin.status}
								</td>
							</tr>
						</tbody>
					))}
				</Table>
			</div>
		)
	}
}
export default withRouter(UserSellingList)
