import { SET_WALLET_INFO } from "../actions/Wallet";

const currentWalletInfo = (state = {}, action) => {
    switch(action.type) {
        case SET_WALLET_INFO:
            return {
                ...state,
                ...action.wallet
            };
        default:
            return state;
    }
}

export default currentWalletInfo;