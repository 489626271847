import React, { Component } from "react"
import { Button } from "antd"
import { Table } from "react-bootstrap"
import { Link } from "react-router-dom"
import { CryptoCoinsOnHome } from "../../services/Cryptoapis"
import { Sparklines, SparklinesLine, SparklinesSpots } from "react-sparklines"
import { withRouter } from "react-router-dom"

class HomesCoinsTable extends Component {
	constructor(props) {
		super(props)
		this.state = { cryptodata: [] }
	}
	async getCryptoData() {
		let data = await CryptoCoinsOnHome()

		this.setState({
			cryptodata: data,
		})
	}

	async componentDidMount() {
		this.getCryptoData()
		this.intervalID = setInterval(this.getCryptoData.bind(this), 5000)
	}

	componentWillUnmount() {
		clearInterval(this.intervalID)
	}
	render() {
		return (
			<div>
				<Table responsive='lg' borderless>
					<thead className='tableHead'>
						<tr>
							<th className='tabbleWidthIcon'>#</th>
							<th className='coinNumberWidth'>Name</th>
							<th className='tableWidth'>Price</th>
							<th className='tableWidth'>Graph</th>
							<th className='coinNumberWidth'>Trade </th>
						</tr>
					</thead>

					{this.state.cryptodata.map((coin, index) => (
						<tbody className='colorBodyColor'>
							<tr>
								<td>{index}</td>
								<td>
									{" "}
									<span>
										{" "}
										<img
											style={{ height: 30, width: 30 }}
											alt=''
											src={coin.icon}
										></img>
									</span>{" "}
									{coin.name}
								</td>
								<td style={{ fontWeight: 500, fontSize: 14 }}>
									{" "}
									${coin.lastprice}{" "}
								</td>
								<td>
									{" "}
									<Sparklines
										data={coin.graphdata}
										width={100}
										height={20}
										margin={5}
									>
										<SparklinesLine
											style={{
												fill: "none",
												strokeWidth: "1",
												stroke: "#2196f3",
											}}
										/>
										<SparklinesSpots />
									</Sparklines>
								</td>
								<td>
									{" "}
									<Link to='/signindev'>
										<Button
											className='coinButton trade'
											type='primary'
										>
											{" "}
											Buy
										</Button>
									</Link>{" "}
								</td>
							</tr>
						</tbody>
					))}
				</Table>
			</div>
		)
	}
}
export default withRouter(HomesCoinsTable)
