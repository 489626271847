import React, { Component } from 'react';
import {Switch, Route, BrowserRouter ,useParams} from 'react-router-dom';
import BasicLayout from '../layout/MainLayout';
import LoadingBar from 'react-redux-loading';
import Profile from './pages/Profile'
import { ProtectedRoute } from './protectedRoute/ProtectedRoutes';
import ResetPassword from '../layout/ResetPassword'
import SignIn from '../layout/SignIn';
import SignUpScreen from '../layout/Signup';
import NotFound from './error/NotFound';
import SidePane from './Modals/SidepaneBuy';
import SidePaneBuy from './Modals/SidePaneSell';
import Home from '../components/pages/Home'

import Notifications from './pages/Notification';
import WalletDetails from './pages/WalletDetails';
import Login from '../layout/Login';
import Homedev from './pages/Homedev';


//This class holds  the main route
class App extends Component {	
	render() {
		return (
			<div> 
                <BrowserRouter >
                <LoadingBar/>
                    <Switch>
                        <Route exact path={`/home`} component={Home}/> 
                         <Route exact path={`/homedev`} component={Homedev}/>  
                        <Route exact path={`/login`} component={Login}/> 
                        <Route exact path={`/signin`} component={SignIn}/> 
                        <Route exact path={`/resetPassword`} component={ResetPassword}/> 
                        <Route exact path={`/signup`} component={SignUpScreen}/> 
                        <ProtectedRoute path='/profile' component={Profile} />
                        <ProtectedRoute path='/notifications' component={Notifications} />
                        <ProtectedRoute path='/sidepane' component={SidePane} />
                        <ProtectedRoute path='/walletdetails' component={WalletDetails} />
                        <ProtectedRoute path='/sidepanesell' component={SidePaneBuy} />
                        <ProtectedRoute path='/' component={BasicLayout} />
                        <Route component={NotFound} />
                    </Switch>
                </BrowserRouter>
			</div>
		);
   }
} 






export default App;
