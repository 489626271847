import React, { Component } from "react"
import { Modal, Row, Col, Card } from "antd"
import { CoinsOnTrade } from "../../services/Cryptoapis"

class CoinSelect extends Component {
	constructor(props) {
		super(props)
		this.state = {
			selectedcoins: [],
		}
	}

	async componentDidMount() {
		let coinToSelect = await CoinsOnTrade()

		this.setState({
			selectedcoins: coinToSelect,
		})

		console.log(this.state.selectedcoins)
	}
	render() {
		const { visible, onCancel, onCreate } = this.props

		return (
			<Modal
				className='login_block modalSell'
				visible={visible}
				okText='Recover'
				onCancel={onCancel}
				onOk={onCreate}
				footer={null}
			>
				<Row>
					{this.state.selectedcoins.map((item) => (
						<Col md={8}>
							<div className='coinSelectCard'>
								<Card style={{ borderRadius: 10 }}>
									<div className='buyCoinImage'>
										<img
											style={{ height: 50, width: 50 }}
											alt=''
											src={item.icon}
										></img>
									</div>
									<div class='buyCoinsValue'>{item.name}</div>
								</Card>
							</div>
						</Col>
					))}
				</Row>
			</Modal>
		)
	}
}

export default CoinSelect
