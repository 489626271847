import React, { Component } from "react"
import { Container } from "react-bootstrap"
import { Row, Col, Card } from "antd"
import PieChart from "../../charts/PieChart"
import Rechart from "../../charts/Rechart"
import PortfolioTable from "../tables/CoinBuyTable"
import TransactionsTable from "../tables/TransactionsTable"

class Portfolio extends Component {
	render() {
		return (
			<React.Fragment>
				<div className='PorfolioHeader'>My Porfolio</div>
				<Col sm={24} md={10}>
					<Row>
						<div className='totalHoldings'>
							<div className='totalHoldingsTitle'>
								Total Holdings
							</div>
							<div className='portfolioChart'>
								<PieChart />
							</div>
							<div className='holdingsCards'>
								<div className='holdingsCard1'>
									<Card style={{ borderRadius: 10 }}>
										<div className='cardText'>
											{" "}
											Most Traded{" "}
										</div>
										<div class='mostTraded'>Etherium</div>
									</Card>
								</div>
								<div className='holdingsCard2'>
									<Card style={{ borderRadius: 10 }}>
										<div className='cardText'>
											{" "}
											Highest Coin{" "}
										</div>
										<div className='highestCoin'>
											Zcash($80,000)
										</div>
									</Card>
								</div>
							</div>
						</div>
					</Row>
					<Row>
						<div className='trend'>
							<div className='trendTitle'>Trend</div>
						</div>
					</Row>
				</Col>
				<Col sm={24} md={14}>
					<div className='coinTable'>
						<div className='totalHoldingsTitle'>Transactions</div>
						<div className='transactionTable'></div>
					</div>
				</Col>
			</React.Fragment>
		)
	}
}

export default Portfolio
