import React, { Component } from "react"
import { Row, Col, Card, Button } from "antd"
import NavBar from "../navBar/Navbar"
import { Link } from "react-router-dom"
import { Container } from "react-bootstrap"
import CoinBuyTable from "../tables/CoinBuyTable"
import HomeCoinsTable from "../tables/HomeCoinsTable"

import HomeCoinStrip from "../tables/HomeCoinStrip"
import WidgetTickerTape from "../../widgets/WidgetTickerTape"

class Home extends Component {
	render() {
		return (
			<React.Fragment>
				<div className='homeImage'>
					<NavBar></NavBar>

					<div className='homeInfo'>
						<Row>
							<Col md={12}>
								<div className='homeInfoText'>
									{" "}
									<span style={{ color: "#ffffff" }}>
										{" "}
										Simplified
									</span>{" "}
									Exchange Experience
								</div>
								<div className='homeInfoDescription'>
									{" "}
									We offer an industry-leading suite along a
									variety of different crypto-native products
									and tools for individuals and institutions
								</div>
								<div className='homeInfoButton'>
									{" "}
									<Link to='/signindev'>
										<Button> Get Started </Button>{" "}
									</Link>
								</div>
							</Col>
							<Col md={12}></Col>
						</Row>
						<WidgetTickerTape />
					
						<div className='coinsTable'>
							<HomeCoinsTable />
						</div>
		
					</div>
				</div>

				<div className='aboutUsSection'>
					<Row>
						<Col md={12}>
							<div className='aboutTextandDescription'>
								<div className='aboutUsText'>About Us</div>
								<div className='aboutUsDescription'>
									Our exchange makes it simple to research &
									learn about the crypto market, allowing you
									to buy bitcoin and other cryptos, and build
									a personalized portfolio of digital money.
									We offer an industry-leading suite along a
									variety of different crypto-native products
									and tools for individuals and institutions.
									Enabling you to grow, manage, and use
									cryptocurrency with world-class security,
									compliance, and customer experience that you
									can trust.
								</div>
							</div>
						</Col>
						<Col md={12}>
							<div className='aboutUsImage'>
								<img
									src={require("../../images/251.jpg")}
								></img>
							</div>
						</Col>
					</Row>
				</div>

				<div className='servicesOffered'>
					<div className='servicesHeader'> Exchange Services</div>

					<Row className='servicesSection'>
						{/* <div className="firstServiceOffered"> */}
						<Col md={12} className='service3'>
							<div className='aboutTextandDescription'>
								<div className='serviceOneNumber'>1</div>
								<div className='serviceOne'>
									Buying and Selling, Made Simple
								</div>
								<div className='serviceOneDescription'>
									You can now trade digital assets, manage
									your holdings, and set recurring buys on one
									of the crypto industry’s most secure,
									trusted platforms.
								</div>
							</div>
						</Col>

						<Col md={12} className='service3'>
							<div className='serviceOneImage'>
								<img
									className='servicesImage'
									src={require("../../images/homeicons/group-2442.png")}
								></img>
							</div>
						</Col>

						{/* </div> */}
					</Row>

					<Row className='servicesSection'>
						<div className='secondServiceOffered'>
							<Col md={12} className='service3'>
								<div className='serviceTwoImage'>
									<img
										className='servicesImage'
										src={require("../../images/homeicons/group-2443.png")}
									></img>
								</div>
							</Col>
							<Col md={12} className='service3'>
								<div className='serviceTwo'>
									<div className='serviceOneNumber'>2</div>
									<div className='serviceOne'>
										Add Multiple Exchange Wallets
									</div>
									<div className='serviceTwoDescription'>
										Flexibility to merge various wallets &
										have access to all your crypto through
										the “Einstein Exchange” simplifying
										smart technology.
									</div>
								</div>
							</Col>
						</div>
					</Row>

					<Row className='servicesSection'>
						<Col md={12} className='service3'>
							<div className='aboutTextandDescription'>
								<div className='serviceOneNumber'>3</div>
								<div className='serviceOne'>
									We Offer Streamlined Trading View
								</div>
								<div className='serviceOneDescription'>
									Access all the tools you need to understand
									the crypto market and start investing – all
									through one clear, attractive interface.
								</div>
							</div>
						</Col>
						<Col md={12} className='service3'>
							<div className='serviceOneImage'>
								<img
									className='servicesImage'
									src={require("../../images/homeicons/group-2444.svg")}
								></img>
							</div>
						</Col>
					</Row>
				</div>
				<div className='newFooter'>
					<div className='footerText'> Eistein Exchange</div>
					<div className='footerImage'>
						<img src={require("../../images/group-68.png")}></img>
					</div>
				</div>
				<div className='newFooter'>
					<div className='footerText'>
						<img
							src={require("../../images/group-61@2x.png")}
						></img>
					</div>
				</div>
			</React.Fragment>
		)
	}
}

export default Home
