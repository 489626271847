import React, { Component } from "react"
import {
	Card,
	Button,
	Input,
	Row,
	Col,
	Icon,
	Form,
	Checkbox,
	message,
} from "antd"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import auth from "../components/protectedRoute/Auth"
import { register } from "../services/Api"
const SET_TOKEN = "SET_TOKEN"
toast.configure()
class SignIn extends Component {
	constructor() {
		super()
		this.state = {
			showpassword: false,
			username: "",
			password: "",
			numberChar: false,
			capitalChar: false,
			smallChar: false,
			confirmDirty: false,
			specialChar: "",
			charlength: false,
		}

		this.handleSubmit = (e) => {
			e.preventDefault()
			this.props.form.validateFields(async (err, values) => {
				if (!err) {
					//   console.log(values)
					if (values.password === values.confirm) {
						// const userValues={
						//     ...values,
						// };
						const auth1 = {
							fullName: values.fullName,
							email: values.email,
							password: values.password,
							redirectLink: "https://einsteinexchange.io/signin",
						}
						const RegisterValue = await register(auth1)

						toast(RegisterValue.message, { type: "success" })
						this.props.history.push("/signin")
					} else
						message.error(
							"Password does not match the confirm password."
						)
				}
			})
		}
	}

	showPass() {
		this.setState({ showpassword: !this.state.showpassword })
	}
	render() {
		const {
			visible,
			onCancel,
			onCreate,
			form,
			receiversWallet,
		} = this.props
		const { getFieldDecorator } = form
		const {
			showpassword,
			numberChar,
			capitalChar,
			smallChar,
			specialChar,
			TooltipShow,
			charlength,
		} = this.state
		const PassPattern = (
			<ul className='PassPattern'>
				<li className={capitalChar ? "active" : ""}>
					At least one capital char
				</li>
				<li className={smallChar ? "active" : ""}>
					At least one small char
				</li>
				<li className={numberChar ? "active" : ""}>
					At least one numeric
				</li>
				<li className={specialChar ? "active" : ""}>
					At least one special char
				</li>
				<li className={charlength ? "active" : ""}>
					Minimum 6 char required
				</li>
			</ul>
		)
		return (
			<React.Fragment>
				<div className='loginImage'>
					<div className='loginCardLayout'>
						<Card
							className='loginCard'
							style={{ borderRadius: 24 }}
						>
							<div className='modalPayment'>
								<div className='LoginHeader'>
									<div className='header'>
										<img
											src={require("../images/group-649.svg")}
										></img>
										{/* Einstein Exchange */}
									</div>
									<div className='loginText'>Register</div>
								</div>
								<Form
									labelCol={{ span: 5 }}
									wrapperCol={{ span: 12 }}
									onSubmit={this.handleSubmit}
								>
									<Form.Item>
										{getFieldDecorator("fullName", {
											initialValue: receiversWallet,
											rules: [
												{
													required: true,
													message:
														"Please input the Full Name",
												},
											],
										})(<Input placeholder='Full Name' />)}
									</Form.Item>
									<Form.Item>
										{getFieldDecorator("email", {
											initialValue: receiversWallet,
											rules: [
												{
													required: true,
													message:
														"Please input email address",
												},
											],
										})(
											<Input placeholder='Email Address' />
										)}
									</Form.Item>

									<Form.Item>
										{getFieldDecorator("password", {
											rules: [
												{
													required: true,
													message:
														"Please input password",
												},
											],
										})(
											<Input
												type={
													showpassword
														? "text"
														: "password"
												}
												placeholder='New Password'
												className='eyeicon'
												minLength={6}
												maxLength={20}
												suffix={
													<img
														src={
															this.state
																.showpassword
																? require("../images/eye-off.svg")
																: require("../images/eye.svg")
														}
														alt='eye'
														onClick={() =>
															this.showPass()
														}
													/>
												}
											/>
										)}
									</Form.Item>
									<Form.Item>
										{getFieldDecorator("confirm", {
											rules: [
												{
													required: true,
													message:
														"Please confirm your password!",
												},
											],
										})(
											<Input
												alt=''
												type={
													showpassword
														? "text"
														: "password"
												}
												placeholder='Confirm Password'
												minLength={6}
												maxLength={20}
												className='eyeicon'
												suffix={
													<img
														src={
															this.state
																.showpassword
																? require("../images/eye-off.svg")
																: require("../images/eye.svg")
														}
														alt='eye'
														onClick={() =>
															this.showPass()
														}
													/>
												}
											/>
										)}
									</Form.Item>

									<Button
										onClick={() => this.showCardModal()}
										className='pricingButtonStyle pricingButton'
										type='primary'
										htmlType='submit'
									>
										Sign Up
									</Button>
								</Form>

								<div className='registerSection'>
									<div className='question'>
										{" "}
										Already have an account?
									</div>
									<div className='register'>
										{" "}
										<Link to='/login'>Login here</Link>
									</div>
								</div>
							</div>
						</Card>
					</div>
				</div>
			</React.Fragment>
		)
	}
}

export default Form.create()(SignIn)
