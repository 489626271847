import React, { Component } from "react"
import { Link, NavLink } from "react-router-dom"
import { toast } from "react-toastify"
import { resetpass } from "../services/Api"
import { withRouter } from "react-router-dom"
import "./signing.scss"

toast.configure()
class ResetPassword extends Component {
	constructor() {
		super()
		this.state = {
			email: "",
			password: "",
			otp: "",
			retypepassword: "",
		}
		this.handleChange = this.handleChange.bind(this)
		this.handleSubmit = this.handleSubmit.bind(this)
	}

	handleChange(e) {
		let target = e.target
		let value = target.type === "checkbox" ? target.checked : target.value
		let name = target.name
		this.setState({
			[name]: value,
		})
	}

	async handleSubmit(e) {
		e.preventDefault()
		console.log("The form was submitted with the following data:")
		console.log(this.state)
		console.log("ID", this.props.location.id)
		const resetValue = {
			otp: this.state.otp,
			password: this.state.password,
			userId: this.props.location.id,
		}
		const ResetValue = await resetpass(resetValue)
		console.log(ResetValue)
		toast(ResetValue.message, { type: "success" })
	}

	render() {
		return (
			<div className='App'>
				<div className='App__Aside'>
					<h1>EinsteinExchange</h1>
				</div>

				<div className='App__Form'>
					<div className='PageSwitcher'>
						<NavLink
							to='/signin'
							activeClassName='PageSwitcher__Item--Active'
							className='PageSwitcher__Item'
						>
							Sign In
						</NavLink>
						<NavLink
							exact
							to='signup'
							activeClassName='PageSwitcher__Item--Active'
							className='PageSwitcher__Item'
						>
							Sign Up
						</NavLink>
					</div>
					<div className='FormCenter'>
						<form
							onSubmit={this.handleSubmit}
							className='FormFields'
							onSubmit={this.handleSubmit}
						>
							<div className='FormField'>
								<label
									className='FormField__Label'
									htmlFor='email'
								>
									OTP
								</label>
								<input
									type='text'
									id='otp'
									className='FormField__Input'
									placeholder='Enter your OTP'
									name='otp'
									onChange={this.handleChange}
								/>
							</div>

							<div className='FormField'>
								<label
									className='FormField__Label'
									htmlFor='email'
								>
									Password
								</label>
								<input
									type='password'
									id='email'
									className='FormField__Input'
									placeholder='Enter your Password'
									name='password'
									onChange={this.handleChange}
								/>
							</div>
							<div className='FormField'>
								<label
									className='FormField__Label'
									htmlFor='password'
								>
									Retype Password
								</label>
								<input
									type='password'
									id='retypepassword'
									className='FormField__Input'
									placeholder='Retype your password'
									name='retypepassword'
									onChange={this.handleChange}
								/>
							</div>

							<div className='FormField'>
								<button className='FormField__Button mr-20'>
									<a>Reset</a>
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		)
	}
}
export default withRouter(ResetPassword)
