import React, { Component } from "react"
import { Table, Modal } from "antd"
import { GetTradeData, changellyGetStatus } from "../../services/Api"
import { trackPromise } from "react-promise-tracker"

class TradeData extends Component {
	constructor(props) {
		super(props)
		this.state = {
			order: "",
			tradedata: [],
			status: [],
			visible: false,
		}
	}

	async componentDidMount() {
		trackPromise(
			this.getTradeData().then((result) => {
				this.setState({
					tradedata: result,
				})
			})
		)
	}

	async getTradeData() {
		const data = []
		const TradeData = await GetTradeData()
		let dataArray = TradeData.data
		console.log(dataArray)

		dataArray.map((item, i) => {
			data.push({
				from: item.resultObj.result.currencyFrom,
				to: item.resultObj.result.currencyTo,
				status: item.resultObj.result.id,
				payinAddress: item.resultObj.result.payinAddress,
				createdAt: item.resultObj.result.createdAt,
			})
		})

		return data
	}

	viewStatus() {
		trackPromise(
			this.getTradeStatus().then((result) => {
				this.setState({
					status: result.data,
					visible: true,
				})
			})
		)
	}

	async getTradeStatus(id) {
		var statusData = {
			id: id,
		}
		let status = await changellyGetStatus(statusData)
		console.log(status)
		return status
	}

	ModalCancel() {
		this.setState({
			visible: false,
		})
	}

	render() {
		const total = this.state.tradedata.length
		const limit = 10
		const columns = [
			{
				title: "Date",
				dataIndex: "createdAt",
				key: "createdAt",
				width: 180,
			},
			{
				title: "Transaction From",
				dataIndex: "from",
				key: "from",
				width: 180,
				render: (text, record) => (
					<a style={{ color: "green" }}>{text} </a>
				),
			},
			{
				title: "Transaction To",
				dataIndex: "to",
				key: "to",
				width: 180,
				render: (text, record) => (
					<a style={{ color: "green" }}>{text} </a>
				),
			},
			{
				title: "Payin Address ",
				dataIndex: "payinAddress",
				key: "payinAddress",
				width: 180,
			},
			{
				title: "Status ",
				dataIndex: "status",
				key: "status",
				width: 180,
				render: (text, record) => (
					<a
						onClick={this.viewStatus(text)}
						style={{ color: "green" }}
					>
						View Status{" "}
					</a>
				),
			},
		]
		return (
			<div>
				<div className='table-responsive'>
					<Table
						bordered
						columns={columns}
						dataSource={this.state.tradedata}
						className='cardtable'
						onChange={this.paginationFun}
						scroll={{ x: 1200 }}
						rowKey='id'
						pagination={{
							size: "small",
							total: total,
							pageSize: limit,
							showTotal: (total) => `Total ${total} Transactions`,
						}}
					/>
				</div>

				<Modal
					title='Status'
					style={{ top: 20 }}
					visible={this.state.visible}
					onOk={() => this.ModalCancel(false)}
					onCancel={() => this.ModalCancel(false)}
				>
					<p>Please make sure you have selected two coins</p>
					<p>
						The current Exchange Amount for {this.state.from} and{" "}
						{this.state.to} is
					</p>
					<p>{this.state.ExchangeAmount}</p>
				</Modal>
			</div>
		)
	}
}
export default TradeData
