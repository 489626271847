import React, { Component } from "react"
import { connect } from "react-redux"
import { Row, Col, Menu, Avatar, Dropdown, Badge } from "antd"
import "./AppHeader.scss"
import { Link } from "react-router-dom"
import { Icon } from "@iconify/react"
import downloadOutlined from "@iconify/icons-ant-design/download-outlined"
import sendOutlined from "@iconify/icons-ant-design/send-outlined"
import { TotalUnreadNotifactions } from "../../calculations/TransactionCalculation"
import { sendTransaction } from "../../services/Ethtransactions"
import { toast } from "react-toastify"
import {
	profileGet,
	getNotification,
	UpdateNotificationStatus,
} from "../../services/Api"
import { MongoTimeConvert } from "../../converters/timeConverters"
import { withRouter } from "react-router-dom"
import RequestPayment from "../Modals/RequestPayment"
import WalletModal from "../Modals/MakePayment"
const SET_TOKEN = "SET_TOKEN"

toast.configure()
class AppHeader extends Component {
	constructor(props) {
		super(props)

		this.state = {
			collapsed: false,
			visible: false,
			paymentModalVisible: false,
			notifications: [],
			username: "",
			unreadnotificationNumber: "",
		}

		this.toggle = () => {
			this.setState({
				collapsed: !this.state.collapsed,
			})
		}

		this.showModal = () => {
			this.setState({ visible: true })
		}
		this.showPaymentModal = () => {
			this.setState({ paymentModalVisible: true })
		}

		this.saveFormRef = (formRef) => (this.formRef = formRef)
		this.saveFormRefPayment = (formPayment) =>
			(this.formPayment = formPayment)
		this.handleCancel = (e) =>
			this.setState({
				visible: false,
				error: false,
				paymentModalVisible: false,
			})

		this.handleCreate = () => {
			const form = this.formRef.props.form
			form.validateFields(async (err, values) => {
				let ReceiverWalletaddress = values.address
				let SenderWalletAddress = values.wallet
				let Amount = values.initialvalue
				const Transaction = await sendTransaction(
					ReceiverWalletaddress,
					SenderWalletAddress,
					Amount
				)
				console.log(Transaction)
			})
		}
		// this is the receive payment modal
		this.receivePayment = () => {
			const form = this.formPayment.props.form
			form.validateFields(async (err, values) => {
				let SenderEmail = values.email
				let ReceiverWalletAddress = values.wallet
				let Amount = values.amount
				console.log(values)
			})
		}
		this.updateNotificationStatus = async (id) => {
			const notificationInfo = {
				notificationId: id,
				isRead: true,
			}

			const notificationProcess = await UpdateNotificationStatus(
				notificationInfo
			)
			console.log(notificationProcess)
			this.props.history.push({
				pathname: "/notifications",
			})
		}

		this.logout = () => {
			localStorage.removeItem(SET_TOKEN)
		}
	}
	async Notifications() {
		const Notifications = await getNotification()
		const NotificationCount = await TotalUnreadNotifactions()
		console.log("Notifications", Notifications.data.isRead)
		this.setState({
			notifications: Notifications.data,
			unreadnotificationNumber: NotificationCount,
		})
	}

	async componentDidMount() {
		const Profile = await profileGet()
		this.Notifications()

		if (Profile.message === "Failed to authenticate token") {
			this.props.history.push("/signin")
		} else {
			this.setState({
				username: Profile.data.fullName,
			})
		}

		// this.intervalID=setInterval(this.Notifications.bind(this),1000)
	}

	componentWillUnmount() {
		clearInterval(this.intervalID)
	}

	render() {
		const menu = (
			<Menu onClick={this.onMenuClick} className='profileHeader'>
				<Menu.Item key='setting'>
					<Link to='/profile'>
						<img src={require("../../images/man-user.png")}></img>
						Profile
					</Link>
				</Menu.Item>
				<Menu.Item key='setting'>
					<Link to='/security'>
						<img
							src={require("../../icons/security-scan-filled.svg")}
						></img>
						Security
					</Link>
				</Menu.Item>
				<Menu.Item onClick={() => this.logout()} key='logout'>
					<Link to='/signin'>
						<img src={require("../../images/logout.svg")}></img>
						Logout
					</Link>
				</Menu.Item>
			</Menu>
		)
		const notification = (
			<Menu className='notificationHeader'>
				<div className='notificationTitle'>
					<span>Notification</span>
					<span>
						<Link to='/notifications'>See All</Link>
					</span>
				</div>
				{this.state.notifications.map((item) => (
					<Menu.Item
						onClick={() => this.updateNotificationStatus(item._id)}
					>
						<div
							className={
								item.isRead === true
									? "notificationTextRead"
									: "notificationTextunRead"
							}
						>
							<span>{item.type}</span>
							<span>{MongoTimeConvert(item.createdAt)}</span>
						</div>
					</Menu.Item>
				))}
			</Menu>
		)
		const notificationNumber = this.state.notifications.length
		return (
			<div className='appHeader'>
				<div className='title-link'>
					<div className='SendReceiveIcons'>
						<Link to='/'>
							{" "}
							<div className='projectTitle'> Ex2 </div>
						</Link>
						<div className='separatedIcons'>
							<div onClick={this.showModal} className='iconSend'>
								<div className='iconandText'>
									<Icon
										onClick={this.showModal}
										className='sendingIcon'
										icon={sendOutlined}
									/>
									<div className='sendText'>Send</div>
								</div>
							</div>

							<span className='seperator'></span>
							<div
								onClick={this.showPaymentModal}
								className='iconSend'
							>
								<div className='iconandText'>
									<Icon
										onClick={this.showPaymentModal}
										className='sendingIcon'
										icon={downloadOutlined}
									/>
									<div className='sendText'>Receive</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className='topmenu'>
					<Menu className='headtoplink' mode='horizontal'>
						<Menu.Item>
							<Dropdown overlay={notification}>
								<a className='ant-dropdown-link'>
									<Badge
										count={
											this.state.unreadnotificationNumber
										}
									>
										<span className='head-example' />
										<img
											src={require("../../icons/group-43.svg")}
										></img>
									</Badge>
								</a>
							</Dropdown>
						</Menu.Item>

						<Menu.Item>
							<Dropdown overlay={menu}>
								<a className='ant-dropdown-link'>
									<div className='profileGroup'>
										<img
											src={require("../../icons/user-3.svg")}
										></img>
										<div className='headerprofileName'>
											{" "}
											{this.state.username}
										</div>
									</div>
								</a>
							</Dropdown>
						</Menu.Item>
					</Menu>
				</div>

				<WalletModal
					wrappedComponentRef={this.saveFormRef}
					visible={this.state.visible}
					onCancel={this.handleCancel}
					onCreate={this.handleCreate}
				/>
				<RequestPayment
					wrappedComponentRef={this.saveFormRefPayment}
					visible={this.state.paymentModalVisible}
					onCancel={this.handleCancel}
					onCreate={this.receivePayment}
				/>
			</div>
		)
	}
}

const mapStateToProps = ({ currentUser }) => {
	return {
		allwallets: currentUser.data,
	}
}
export default withRouter(connect(mapStateToProps)(AppHeader))
