import React, { Component } from "react"
import { Col, Row, Button } from "antd"
import { profileGet } from "../../services/Api"
import { Link } from "react-router-dom"
import { Icon, InlineIcon } from "@iconify/react"
import securityScanFilled from "@iconify/icons-ant-design/security-scan-filled"
class Security extends Component {
	constructor(props) {
		super(props)
		this.state = {
			email: "",
			status: false,
			twofactor: false,
		}
	}

	async componentDidMount() {
		const Profile = await profileGet()

		console.log("PROFILE", Profile.data)

		this.setState({
			email: Profile.data.email,
			status: Profile.data.isVerified,
		})
	}

	render() {
		return (
			<React.Fragment>
				<div className='basicAdvancedSecurity'>
					<div className='securityHeader'>Security</div>
					<div className='buysellButton'>
						<Link to='#'>
							{" "}
							<Button className='buyButtontab activeButton'>
								Basic
							</Button>
						</Link>
						<Link to='#'>
							<Button className='sellButtontab'>Advanced</Button>
						</Link>
					</div>
				</div>
				<div className='generalSecurity'>
					<Row>
						<Col md={8}>
							<div className='instruction1'>
								Complete the steps below to help prevent
								unauthorized access to your wallet. Add
								additional verification to access your funds at
								any time.
							</div>
						</Col>
						<Col md={8}>
							<div className='instruction2'>
								<div
									className={
										this.state.status === true
											? "numberTick"
											: "numberCircle"
									}
								>
									<div className='number'>1</div>
								</div>
								<div className='instructiontext'>
									Verify Your email
								</div>
							</div>
						</Col>
						<Col md={8}>
							<div className='instruction3'>
								<div
									className={
										this.state.twofactor === true
											? "numberTick"
											: "numberCircle"
									}
								>
									<div className='  number'>2</div>
								</div>
								<div className='instructiontext'>
									{" "}
									Turn on Two-Step Verification
								</div>
							</div>
						</Col>
					</Row>
				</div>
				<div className='emailVerification'>
					<Row>
						<Col md={8}>
							<div className='iconSecurity'>
								<div
									className={
										this.state.status === true
											? "numberTick"
											: "numberCircle"
									}
								>
									<Icon
										className='securityIcon'
										icon={securityScanFilled}
									/>
								</div>
							</div>
						</Col>

						<Col md={8}>
							<div className='verificationHeader'>
								Email Address
							</div>
							<div className='emailVerificationText'>
								You’ve verified{" "}
								<strong> {this.state.email}</strong> Select
								"Edit Your Profile" to modify this existing
								address. We will use this email to authorize
								logins, send payment notifications, and notify
								you of wallet updates.
							</div>
						</Col>

						<Col md={8}>
							<div className='profileEdit'>
								<Link to='/profile'> Edit Your Profile</Link>
							</div>
						</Col>
					</Row>
				</div>
				<div className='twoFactorAuthentication'>
					<Row>
						<Col md={8}>
							<div className='iconSecurity'>
								<Icon
									className='securityIcon numberCircle'
									icon={securityScanFilled}
								/>
							</div>
						</Col>

						<Col md={8}>
							<div className='verificationHeader'>
								2 step Authentication
							</div>
							<div className='emailVerificationText'>
								Two-step verification helps to prevent
								unauthorized access to your wallet by requiring
								a one-time password for every login attempt.
								Enable this to further secure your wallet.
							</div>
						</Col>
						<Col md={8}>
							<div className='buttonEnable'>
								<Button>
									{" "}
									<div className='enableText'> Enable</div>
								</Button>
							</div>
						</Col>
					</Row>
				</div>
			</React.Fragment>
		)
	}
}

export default Security
