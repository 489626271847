import React, { Component } from "react"
import { Modal, Row, Col } from "antd"

class NewsModal extends Component {
	constructor(props) {
		super(props)
		this.state = {
			wallets: [],
		}
	}

	render() {
		const {
			visible,
			onCancel,
			onCreate,
			description,
			topic,
			image,
			time,
			link,
		} = this.props

		return (
			<Modal
				className='login_block'
				visible={visible}
				title='View the News'
				okText='Recover'
				onCancel={onCancel}
				onOk={onCreate}
				footer={null}
				width={700}
			>
				<Row>
					<Col span={12} sm={24} md={{ span: 12 }} lg={{ span: 12 }}>
						<div>
							<img alt='example' src={image} />
						</div>
					</Col>

					<Col span={12} md={{ span: 12 }} sm={24} lg={{ span: 12 }}>
						<p className='newsTopic'>{topic}</p>
					</Col>
				</Row>

				<p>{time}</p>
				<p>{description}</p>
				<a style={{ color: "#8f94fb" }} href={link} target='_blank'>
					Read more
				</a>
			</Modal>
		)
	}
}

export default NewsModal
