import React, { Component } from "react"
import SlidingPane from "react-sliding-pane"
import { Button, Icon, Checkbox } from "antd"
import "./Sidepane.scss"

class SidePaneBuy extends Component {
	constructor(props) {
		super(props)
		this.state = {
			isPaneOpen: false,
			isPaneOpenLeft: false,
			coinSelected: "",
			coinsValues: "9000",
			isModal: "",
		}
		this.closeSidePane = () => {
			this.setState({
				isPaneOpen: false,
			})
		}
	}

	render() {
		const {
			isOpen,
			closeSidePane,
			Coin,
			CoinValue,
			onChange,
			coinIcon,
		} = this.props
		return (
			<div>
				<SlidingPane
					className='some-custom-class'
					closeIcon={<div></div>}
					overlayClassName='some-custom-overlay-class'
					isOpen={isOpen}
					width='300px'
					onRequestClose={() => {
						// triggered on "<" on left top click or on outside click
						this.setState({ isPaneOpen: false })
					}}
				>
					<div className='sidePaneSell'>
						<div className='sidepaneHeader'>
							<div>Buy {Coin}</div>
							<div onClick={closeSidePane}>
								<Icon
									type='close'
									style={{ fontSize: 20 }}
								></Icon>
							</div>
						</div>
						<div className='sidepaneTitle'>
							<div>
								<img
									style={{
										height: 30,
										width: 30,
										color: "#08c",
									}}
									alt=''
									src={coinIcon}
								></img>
								<span> {Coin}</span>
							</div>
							<div> ${CoinValue}</div>
						</div>
						<div className='convertorHeader'>Amount</div>
						<div className='convertorSection'>
							<input type='number' onChange={onChange}></input>

							<div>
								{" "}
								<Icon
									style={{ fontSize: "18px" }}
									type='swap'
								/>
							</div>
							<input type='text' value={CoinValue}></input>
						</div>

						<div className='paymentHeader'>Payment Method</div>
						<div className='paymentSection'>
							<input
								type='text'
								placeholder='XXX XXX XXX-342'
							></input>
						</div>
						<div>
							<Checkbox>
								I agree to{" "}
								<span style={{ color: "#e4b28d" }}>
									Terms and conditions
								</span>
							</Checkbox>{" "}
						</div>
						<div className='buttonSection'>
							<Button className='payButton'>
								Pay ${CoinValue}
							</Button>
						</div>
					</div>
				</SlidingPane>
			</div>
		)
	}
}

export default SidePaneBuy
