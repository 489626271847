import { SET_USER } from "../actions/User";

const currentUser = (state = {}, action) => {
    switch(action.type) {
        case SET_USER:
            return {
                ...state,
                ...action.user
            };
        default:
            return state;
    }
}

export default currentUser;